import * as _ from "lodash";
import { Component, inject, Input, ViewEncapsulation } from "@angular/core";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { DefinitionsCostDriver, IAppDefinitions } from "@shared/app-definitions.types";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { TransferRulesComponentBase } from "../bases/transfer-rules-base/transfer-rules-component-base";
import { RuleGroup } from "../bases/rules-base/gateways/rules-gateway.types";
import { SOURCE_RULE_FILTER_SELECTOR, TARGET_RULE_FILTER_SELECTOR, TransferRulesPivotLevel2 } from "../bases/transfer-rules-base/types";
import { TransferCostDriverInfo, TransferRule } from "../bases/transfer-rules-base/gateway/transfer-rules-gateway.type";

import { EditResourceToTaskRulesGateway } from "./gateways/edit-resource-to-task-rules-gateway";
import { TaskManagementDialog } from "@shared/dialogs/task-management-dialog/task-management-dialog.component";


@Component({
    selector: "mod-edit-resource-to-task-rules",
    templateUrl: "./edit-resource-to-task-rules.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog.ResourceToTaskRules"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        { provide: TARGET_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
    ],
})
export class EditResourceToTaskRulesComponent extends TransferRulesComponentBase {

    private _taskManagementDialog = inject(TaskManagementDialog);
    _gateway = inject(EditResourceToTaskRulesGateway);

    // ----------------------------------------------------------------------------------
    // Fields

    @Input() isReadonly = false;

    _costDriverDropdown: IDropdownDefinition<number>;
    protected _defaultCostDriver: DefinitionsCostDriver;

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["task"];


    // ----------------------------------------------------------------------------------
    // Methods

    protected _processLoadedData(
        rules: TransferRule[],
        groups: RuleGroup[],
        costDrivers: TransferCostDriverInfo[]
    ): void {
        super._processLoadedData(rules, groups, costDrivers);

        const orderedCostDrivers = _.sortBy(
            _.map(costDrivers, x => this._definitions.costDriver[x.costDriverId]),
            "orderBy"
        );
        this._costDriverDropdown = this._defineCostDriverDropdown(orderedCostDrivers);
        this._defaultCostDriver = _.first(orderedCostDrivers);
    }


    _isTargetAllowed(row: TransferRulesPivotLevel2, field: string): boolean {
        return true;
    }


    protected _processLoadedCostDriverInfo(costDrivers: TransferCostDriverInfo[]): void {
        this._costDriversInfoLookup = _.keyBy(costDrivers, "costDriverId");
    }


    protected _newRule(groupId: number, position: number) {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
            costDriverId: this._defaultCostDriver.id,
        } as TransferRulesPivotLevel2;
    }


    _openTaskManager(): void {
        this._taskManagementDialog
            .show({
                isReadonly: this.isReadonly,
            });
    }
}
