import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TranslateCompiler, TranslateModule } from "@ngx-translate/core";

import { ConsoleLogLevel, CoreModule, LG_APPLICATION_MULTI_EVENT_TRACERS, LgConsoleConfiguration, LgErrorHandler } from "@logex/framework/core";
import { LG_ICON_LOADER_CONTENT, LG_SNACKBAR_OPTIONS, UiCoreModule } from "@logex/framework/ui-core";
import {
    IScenarioDialogConfig,
    LG_FW_UI_STATE_SERVICE,
    LG_SCENARIO_DIALOG_CONFIG,
    LG_SCENARIO_GATEWAY,
    LgLayoutModule,
    LgUserStorageFwUiStateService
} from "@logex/framework/lg-layout";
import {
    IHelpTooltipConfig,
    LG_HELP_TOOLTIP_CONFIG,
    LG_PASTE_DIALOG_CONFIGURATION,
    PasteDialogConfiguration,
    UiToolboxModule
} from "@logex/framework/ui-toolbox";
import { LG_LOCALIZATION_SETTINGS, LgLocalizationModule, ReferenceTranslateCompiler, useMessageFormatLocales } from "@logex/framework/lg-localization";
import {
    ILgFeatures,
    LG_APP_CONFIGURATION,
    LG_APP_CONTROL,
    LG_APP_DEFINITIONS,
    LG_APP_GET_PERIOD_NAME,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_APPLICATION_INSIGHTS_CONFIGURATION,
    LG_AUTHENTICATION_SERVICE,
    LG_AUTHORIZATION_SERVICE,
    LG_DEFINITIONS_HIERARCHY_SERVICE,
    LG_FEATURES,
    LG_LAST_FILTER_STORAGE,
    LG_MATOMO_CONFIGURATION,
    LG_MESSAGE_BUS_SERVICE,
    LG_NAVIGATION,
    LG_POSTPONE_MATOMO_CONFIGURATION,
    LG_USER_INFO,
    LG_USER_STORAGE_SERVICE_GATEWAY,
    LG_USERFLOW_CONFIGURATION,
    LgAppControlV2Service,
    LgApplicationInsightsService,
    LgApplicationModule,
    LgApplicationPermissionsBase,
    LgAuthorizationServiceV1,
    LgBackendApplicationInsightsConfiguration,
    LgDefinitionsHierarchyService,
    LgFeaturesService,
    LgMatTrackingService,
    LgMessageBusService,
    LgUserflowConfiguration,
    LgUserStorageGatewayV2,
    OidcAuthService,
    ScenarioService,
    VersionService
} from "@logex/framework/lg-application";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { LgExportsModule } from "@logex/framework/lg-exports";
import { FLEXIBLE_LAYOUTS_NAVIGATION, flexibleIconsAtlas, FlexibleLayoutsNavigationService, FlexibleModule } from "@logex/flexible";

import { ExportsModule } from "@modules/exports";

import { AppConfiguration } from "@logex/fadp";
import { AppStartupService } from "@shared/services/app-startup.service";
import { AppSession } from "@shared/types/app-session";
import { navigation } from "@shared/app-navigation";
import { SharedModule } from "@shared/shared.module";
import { ApplicationPermissions } from "@shared/app-permissions";
import { AppUser } from "@shared/app-user";
import { AppScenarioGateway } from "@shared/app-scenario-gateway";

import { AppLgLocalizationSettings } from "@shared/services/app-lg-localization-settings";
import { AppInfo } from "@shared/app-info";

import { APP_VERSION_TITLE, AppDefinitions, IAppDefinitions } from "@shared";
import { APP_HELP_LINKS } from "@shared/app-help";
import { APP_BANNER_CONFIG } from "@shared/types/app-banner";
import { AppFeatures } from "@shared/app-features";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthorizedComponent } from "./authorized.component";

import iconAtlas from "./assets/app/svg-icons/app-icons.def";
import coloredIconsAtlas from "./assets/app/svg-icons/colored-icons-sheet";
import { LastFilterStorageService } from "@shared/services/last-filter-storage.service";

import { RulesDialogModule } from "@shared/dialogs/rules-dialog/rules-dialog.module";
import { EditRulesDialogModule } from "./shared/dialogs/edit-rules-dialog/edit-rules-dialog.module";
import { RulesDialogSwitcherService } from "./shared/dialogs/edit-rules-dialog/services/rules-dialog-switcher.service";

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler },
        }),
        FormsModule,
        HttpClientModule,
        CoreModule,
        UiCoreModule,
        UiToolboxModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization",
        }),
        LgFiltersetModule.forRoot(),
        LgPivotTableModule,
        LgApplicationModule.forRoot(),
        LgExportsModule,
        LgLayoutModule,
        SharedModule,
        FlexibleModule,
        ExportsModule,
        RulesDialogModule,
        EditRulesDialogModule,

        AppRoutingModule // last!
    ],
    declarations: [
        AppComponent,
        AuthorizedComponent,
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                // TODO: Configure logging in appsettings.json
                const config = new LgConsoleConfiguration();
                // config.logger( "Logex.Directives.LgPivotTableController", ConsoleLogLevel.Error );
                // config.logger( "Logex.Application.ServerGatewayBase", ConsoleLogLevel.Error );
                config.logger("Logex.Application", ConsoleLogLevel.Debug);
                config.logger("Logex.OidcAuthService", ConsoleLogLevel.Warn);
                // ( window as any ).fakeCache = true;
                return config;
            },
        },
        {
            provide: APP_VERSION_TITLE,
            useValue: "2023",
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser(),
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new AppConfiguration(),
        },
        {
            provide: LG_APP_SESSION,
            useValue: new AppSession(),
        },
        {
            provide: LG_APP_INFO,
            useValue: new AppInfo(),
        },
        {
            provide: LG_SCENARIO_GATEWAY,
            useClass: AppScenarioGateway
        },
        {
            provide: VersionService,
            useExisting: ScenarioService,
        },
        useMessageFormatLocales(["en-150", "en-GB", "cy-GB", "nl", "nl-NL", "fi-FI", "de-DE", "sv-SE", "nb-NO", "da-DK", "zu-ZA"]),
        // {
        //     provide: LOCALE_ID,
        //     useFactory: ( service: TranslateService ) => service.currentLang,
        //     deps: [TranslateService],
        // },
        {
            provide: LG_APP_GET_PERIOD_NAME,
            useValue: (version, showDataVersionPeriod?): string => {
                let prefix = "";
                switch (version.periode) {
                    case 1:
                        prefix = "Q1 ";
                        break;
                    case 2:
                        prefix = "Q1-Q2 ";
                        break;
                    case 3:
                        prefix = "Q1-Q3 ";
                        break;
                }
                return prefix + version.data_version;
            },
        },
        {
            provide: LG_APP_DEFINITIONS,
            useExisting: AppDefinitions,
        },
        {
            provide: FLEXIBLE_LAYOUTS_NAVIGATION,
            useClass: FlexibleLayoutsNavigationService,
        },
        {
            provide: LG_NAVIGATION,
            useFactory: () => navigation(
                inject(LG_FEATURES),
                inject(RulesDialogSwitcherService),
                inject(FLEXIBLE_LAYOUTS_NAVIGATION),
            ),
        },
        {
            provide: LgApplicationPermissionsBase,
            useExisting: ApplicationPermissions,
        },
        {
            provide: ErrorHandler,
            useClass: LgErrorHandler,
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: OidcAuthService,
        },
        {
            provide: LG_AUTHORIZATION_SERVICE,
            useClass: LgAuthorizationServiceV1,
        },
        {
            provide: LG_MESSAGE_BUS_SERVICE,
            useClass: LgMessageBusService,
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLgLocalizationSettings,
        },
        {
            provide: LG_APP_CONTROL,
            useClass: LgAppControlV2Service,
        },
        {
            provide: LG_USER_STORAGE_SERVICE_GATEWAY,
            useClass: LgUserStorageGatewayV2,
        },
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: coloredIconsAtlas,
            multi: true
        },
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: iconAtlas,
            multi: true
        },
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: flexibleIconsAtlas,
            multi: true
        },
        {
            provide: LG_SCENARIO_DIALOG_CONFIG,
            useFactory: (session = inject<AppSession>(LG_APP_SESSION)) => ({
                showScenarioPeriodName: true,
                allowScenarioDelete: true,
                allowScenarioCreate: true,
                reloadOnScenarioChange: true,
                allowScenarioLock: (scenario, userInfo) => {
                    return userInfo.roles?.cst_manage_scenarios
                },
                getCurrentScenario: () => session.scenarioId,
            } as IScenarioDialogConfig)
        },
        {
            provide: LG_PASTE_DIALOG_CONFIGURATION,
            useValue: {
                allowNullsToZeros: true,
                allowSkipErrors: true,
                allowPasteMode: true,
            } as PasteDialogConfiguration,
        },
        {
            provide: LG_HELP_TOOLTIP_CONFIG,
            useFactory: (features: ILgFeatures) => {
                const config: IHelpTooltipConfig = {
                    appId: "costing",
                    isDisabled: false,
                };
                features.onChanged.subscribe(() => {
                    config.isDisabled = !features.isFeatureEnabled(AppFeatures.SHOW_HELP_TOOLTIPS);
                });
                return config;
            },
            deps: [LG_FEATURES],
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgUserStorageFwUiStateService,
        },
        {
            provide: LG_SNACKBAR_OPTIONS,
            useValue: {}
        },
        // Trackers
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgApplicationInsightsService,
            multi: true,
        },
        {
            provide: LG_APPLICATION_INSIGHTS_CONFIGURATION,
            useExisting: LgBackendApplicationInsightsConfiguration
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true,
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useValue: LG_POSTPONE_MATOMO_CONFIGURATION,
        },
        {
            provide: LG_USERFLOW_CONFIGURATION,
            useClass: LgUserflowConfiguration
        },
        {
            provide: LG_FEATURES,
            useClass: LgFeaturesService,
        },
        {
            provide: LG_LAST_FILTER_STORAGE,
            useClass: LastFilterStorageService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.getInitializer(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: APP_HELP_LINKS,
            useValue: {},
        },
        {
            provide: APP_BANNER_CONFIG,
            useValue: {},
        },
        {
            provide: LG_DEFINITIONS_HIERARCHY_SERVICE,
            useFactory: () => new LgDefinitionsHierarchyService<IAppDefinitions>(inject(AppDefinitions), {
                activity: {
                    subgroupCode: "activitySubgroup",
                    typeId: "activityType",
                    sourceTypeCode: "activitySourceType",
                    benchmarkTypeId: "activityBenchmarkType",
                },
                activitySubgroup: {
                    groupCode: "activityGroup",
                },
                activityGroup: {
                    topgroupCode: "activityTopgroup",
                },
                account: {
                    typeId: "accountType",
                    regulationTypeCode: "accountRegulationType",
                    subgroupCode: "accountSubgroup",
                    benchmarkTypeId: "accountBenchmarkType",
                    financialStatementGroupCode: "financialStatementGroup",
                    subAccountCode: "subAccount"
                },
                accountRegulationType: {
                    subgroupCode: "accountRegulationTypeSubgroup"
                },
                accountSubgroup: {
                    groupCode: "accountGroup",
                },
                product: {
                    groupCode: "productGroup",
                    typeCode: "productType",
                },
                productGroup: {
                    topgroupCode: "productTopgroup",
                },
                costCentre: {
                    subgroupCode: "costCentreSubgroup",
                    typeId: "costCentreType",
                    benchmarkTypeId: "costCentreBenchmarkType",
                    regulationTypeCode: "costCentreRegulationType",
                    level1Code: "costCentreLevel1",
                    level2Code: "costCentreLevel2",
                    level3Code: "costCentreLevel3",
                    level4Code: "costCentreLevel4",
                    level5Code: "costCentreLevel5",
                },
                costCentreRegulationType: {
                    subgroupCode: "costCentreRegulationTypeSubgroup"
                },
                costCentreSubgroup: {
                    groupCode: "costCentreGroup",
                },
                costCentreGroup: {
                    topgroupCode: "costCentreTopgroup",
                },
                financialStatementGroup: {
                    categoryCode: "financialStatementCategory",
                },
                jobTitle: {
                    typeId: "jobTitleType",
                    groupCode: "jobTitleGroup",
                    benchmarkTypeId: "jobTitleBenchmarkType",
                },
                machine: {
                    typeId: "machineType",
                },
                material: {
                    typeId: "materialType"
                },
                productionUnit: {
                    costCentreCode: "costCentre",
                    typeId: "productionUnitType",
                    benchmarkTypeId: "productionUnitBenchmarkType",
                    level1Code: "costCentreLevel1",
                    level2Code: "costCentreLevel2",
                    level3Code: "costCentreLevel3",
                    level4Code: "costCentreLevel4",
                    level5Code: "costCentreLevel5",
                },
                resource: {
                    typeCode: "resourceType",
                },
                room: {
                    costCentreCode: "costCentre",
                },
                regulationResource: {
                    subgroupCode: "regulationResourceSubgroup",
                    groupCode: "regulationResourceGroup",
                    topgroupCode: "regulationResourceTopgroup",
                },
                regulationTask: {
                    subgroupCode: "regulationTaskSubgroup",
                },
                specialism: {
                    systemCode: "specialismSourceType"
                }
            }),
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
