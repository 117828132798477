import * as _ from "lodash";
import { inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { mapTargetFilters } from "@shared/services/rule-filter-selector/utils/loadTargetFilters";
import { RulesComponentBase } from "../rules-base/rules-component-base";

import { SelectTransferFiltersSchema, TransferCostDriverInfo, TransferRule } from "./gateway/transfer-rules-gateway.type";
import { TransferRulesPivotLevel2 } from "./types";


export abstract class TransferRulesComponentBase<TRulesPivotLevel2 extends TransferRulesPivotLevel2 = TransferRulesPivotLevel2,
    TDriverInfo extends TransferCostDriverInfo = TransferCostDriverInfo> extends RulesComponentBase<TRulesPivotLevel2, TDriverInfo> {

    _targetRuleFilterSelector = inject(RuleFilterSelectorService);

    constructor() {
        super();
        
        this._targetRuleFilterSelector.configure({
            selectOptionsCallback: (uid, row) => this._gateway.selectTargetFilterData({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                uid,
            })
        });
    }


    // ----------------------------------------------------------------------------------
    public _targetSelectorConfig: RuleFilterInfo[];


    // ----------------------------------------------------------------------------------
    protected async _processFiltersSchema(filtersSchema: SelectTransferFiltersSchema): Promise<void> {
        this._sourceSelectorConfig = mapTargetFilters(filtersSchema.filters, this._lgTranslate);
        this._targetSelectorConfig = mapTargetFilters(filtersSchema.targetFilters, this._lgTranslate);

        const schemaDefinitions = _.compact(
            _.uniq([
                ...this._sourceSelectorConfig.map(x => x.type),
                ...this._targetSelectorConfig.map(x => x.type),
            ])
        );

        await firstValueFrom(this._definitions.load(
            "costDriver",
            ...this._requiredDefinitions,
            ...schemaDefinitions as any,
        ));
    }


    protected _areEqualRules(row: TRulesPivotLevel2, originalRow: TransferRule): boolean {
        return super._areEqualRules(row, originalRow)
            && this._areEqualFilters(this._sourceSelectorConfig, row.filters, originalRow.filters)
            && this._areEqualFilters(this._targetSelectorConfig, row.targetFilters, originalRow.targetFilters);
    }


    protected _updateRuleAfterChanges(row: TRulesPivotLevel2, changedFields: Readonly<Partial<TransferRulesPivotLevel2>>): void {
        row.allowedTargetUids = this._getCostDriverInfo(row)?.targetUids;

        // Clear all target fields that are not allowed in this cost driver
        _.forOwn(row.targetFilters, (value, field) => {
            if (!this._isTargetAllowed(row, field)) {
                delete row.targetFilters[field];
            }
        });

        if (row?.targetFilters && Object.keys(row?.targetFilters)?.length === 0) {
            row.targetFilters = undefined;
        }
    }


    _isTargetAllowed(row: TransferRulesPivotLevel2, field: string): boolean {
        return _.includes(row.allowedTargetUids, field);
    }


    async _onTargetFilterChange(
        row: TransferRulesPivotLevel2,
        selector: string,
        value: string[]
    ): Promise<void> {
        row.targetFilters = this._updatedFilters(row.targetFilters, selector, value);
        this._markModified(row);
    }

}
