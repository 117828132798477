import * as _ from "lodash";
import { Component, inject, Injectable, ViewChild } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import { getDialogFactoryBase, IDialogComponent, LgDialogFactory, LgDialogRef, LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LG_APP_SESSION, LG_FEATURES } from "@logex/framework/lg-application";
import { DialogMixin, ModalResultDialogMixin, TabbedMixin } from "@logex/mixins";

import { AppFeatures } from "@shared/app-features";
import { HelpTooltip } from "@shared";

import { AppDefinitions } from "../../app-definitions.service";
import { PageTabs, Tabs } from "./types/constants";
import { AppSession } from "../../types/app-session";
import { RulesComponentBase } from "./components/bases/rules-base/rules-component-base";
import { EditRulesDialogAuthorization } from "./services/authorization.service";
import { EditAccountTransferRulesComponent } from "./components/edit-account-transfer-rules/edit-account-transfer-rules.component";
import { EditResourceTransferRulesComponent } from "./components/edit-resource-transfer-rules/edit-resource-transfer-rules.component";
import { EditResourceTaskTransferRulesComponent } from "./components/edit-resource-task-transfer-rules/edit-resource-task-transfer-rules.component";
import { EditAccountToResourceRulesComponent } from "./components/edit-account-to-resource-rules/edit-account-to-resource-rules.component";
import { EditResourceToTaskRulesComponent } from "./components/edit-resource-to-task-rules/edit-resource-to-task-rules.component";
import { EditResourceTaskToActivityRulesComponent } from "./components/edit-resource-task-to-activity-rules/edit-resource-task-to-activity-rules.component";
import { EditAccountSplitRulesComponent } from "./components/edit-split-rules/edit-account-split-rules.component";
import { EditResourceTaskSplitRulesComponent } from "./components/edit-resource-task-split-rules/edit-resource-task-split-rules.component";


export interface EditRulesDialogDialogArguments {
    tab?: Tabs;
}


export interface EditRulesDialogDialogResult {
    updatedTabs: Tabs[];
    reload: boolean;
}


interface TabsConfigItem {
    page: PageTabs;
    nameLc: string;
    tabs: TabsConfigTabItem[];
    help?: HelpTooltip;
}

interface TabsConfigTabItem {
    tab: Tabs;
    nameLc: string;
    isDefault?: boolean;
    ngIf?: boolean;
    help?: HelpTooltip;
}


export interface EditRulesDialogComponent extends DialogMixin<EditRulesDialogComponent>,
    ModalResultDialogMixin<EditRulesDialogDialogArguments, EditRulesDialogDialogResult>,
    TabbedMixin {
}


@Component({
    selector: "app-edit-rules-dialog",
    templateUrl: "./edit-rules-dialog.component.tshtml",
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog"),
    ],
})
@mixins(DialogMixin, ModalResultDialogMixin, TabbedMixin)
export class EditRulesDialogComponent implements IDialogComponent<EditRulesDialogComponent> {

    _promptDialog = inject(LgPromptDialog);
    _definitions = inject(AppDefinitions);
    _dialogRef = inject(LgDialogRef<EditRulesDialogComponent>);
    _lgTranslate = inject(LgTranslateService);
    private _authorization = inject(EditRulesDialogAuthorization);
    private _session = inject<AppSession>(LG_APP_SESSION);
    private _features = inject(LG_FEATURES);

    constructor() {
        this._initMixins();

        this._isReadonly = !this._authorization.edit || this._session.scenario.isLocked || false;
    }

    // ----------------------------------------------------------------------------------
    //

    // Dialog configuration
    _dialogClass = "lg-dialog lg-dialog--7col";
    _title = this._lgTranslate.translate(".DialogTitle");

    // Fields
    @ViewChild(EditAccountSplitRulesComponent, { static: true }) private _editAccountSplitRule: EditAccountSplitRulesComponent;
    @ViewChild(EditAccountTransferRulesComponent, { static: true }) private _editAccountTransferRule: EditAccountTransferRulesComponent;
    @ViewChild(EditAccountToResourceRulesComponent, { static: true }) private _editAccountToResourceRule: EditAccountToResourceRulesComponent;
    @ViewChild(EditResourceTransferRulesComponent, { static: true }) private _editResourceTransferRule: EditResourceTransferRulesComponent;
    @ViewChild(EditResourceToTaskRulesComponent, { static: true }) private _editResourceToTaskRule: EditResourceToTaskRulesComponent;
    @ViewChild(EditResourceTaskTransferRulesComponent, { static: true }) private _editResourceTaskTransferRule: EditResourceTaskTransferRulesComponent;
    @ViewChild(EditResourceTaskSplitRulesComponent, { static: true }) private _editResourceTaskSplitRule: EditResourceTaskSplitRulesComponent;
    @ViewChild(EditResourceTaskToActivityRulesComponent, { static: true }) private _editResourceTaskToActivityRule: EditResourceTaskToActivityRulesComponent;

    private _currentTransferRule: RulesComponentBase;
    private _updatedTabs: Set<Tabs> = new Set<Tabs>();

    _isReadonly: boolean;


    _currentPageTab: PageTabs;
    _currentRuleTab: Tabs;

    _tabsConfig: TabsConfigItem[] = [
        {
            page: PageTabs.OrganisationCost,
            nameLc: ".OrganisationCostTab",
            help: HelpTooltip.OrganisationCost,
            tabs: [
                {
                    tab: Tabs.AccountSplitRules,
                    nameLc: ".AccountSplitRulesTab",
                    help: HelpTooltip.SubAccountSpecification,
                },
                {
                    tab: Tabs.AccountTransferRules,
                    nameLc: ".AccountTransferRulesTab",
                    isDefault: true,
                    help: HelpTooltip.AccountTransfer,
                },
            ]
        },
        {
            page: PageTabs.AllocateToResource,
            nameLc: ".AllocateToResourceTab",
            help: HelpTooltip.AllocateToResource,
            tabs: [
                {
                    tab: Tabs.AccountToResourceRules,
                    nameLc: ".AccountToResourceRulesTab",
                    help: HelpTooltip.ResourceSpecification,
                },
                {
                    tab: Tabs.ResourceTransferRules,
                    nameLc: ".ResourceTransferRulesTab",
                    isDefault: true,
                    help: HelpTooltip.ResourceTransfer,
                },
            ]
        },
        {
            page: PageTabs.AllocateSupportCost,
            nameLc: ".AllocateSupportCostTab",
            help: HelpTooltip.AllocateSupportCost,
            tabs: [
                {
                    tab: Tabs.ResourceToTaskRules,
                    nameLc: ".ResourceToTaskRulesTab",
                    help: HelpTooltip.TaskSpecification,
                },
                {
                    tab: Tabs.ResourceTaskTransferRules,
                    nameLc: ".ResourceTaskTransferRulesTab",
                    isDefault: true,
                    help: HelpTooltip.SupportCostTransfers,
                },
            ]
        },
        {
            page: PageTabs.AllocateToProduction,
            nameLc: ".AllocateToProductionTab",
            help: HelpTooltip.AllocateToProduction,
            tabs: [
                {
                    tab: Tabs.ResourceTaskSplitRules,
                    nameLc: ".ResourceTaskSplitRulesTab",
                    ngIf: this._features.isFeatureEnabled(AppFeatures.SUBTASKS),
                    help: HelpTooltip.SubTaskSpecification,
                },
                {
                    tab: Tabs.ResourceTaskToActivityRules,
                    nameLc: ".ResourceTaskToActivityRulesTab",
                    isDefault: true,
                    help: HelpTooltip.ProductionAllocations,
                },
            ]
        },
    ];


    private _rulePage: _.Dictionary<PageTabs> = _.reduce(this._tabsConfig, (r, x) => {
        for (const tab of x.tabs) {
            r[tab.tab] = x.page;
        }
        return r;
    }, {});


    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._initialized = true; // Set earlier so that we can switch tabs
        await this._setRuleTab(this._args.tab != null ? this._args.tab : Tabs.AccountSplitRules);
    }


    async _setPageTab(page: PageTabs, force = false) {
        if (force || await this._onTabChanging(null, this._currentRuleTab)) {
            this._currentPageTab = page;

            // Set default tab if needed
            const pageTabs = this._tabsConfig[page].tabs;
            if (!pageTabs.map(x => x.tab).includes(this._currentRuleTab)) {
                const defaultTab = pageTabs.find(x => x.isDefault);
                await this._setRuleTab(defaultTab.tab, true);
            }
        }
    }


    async _setRuleTab(tab: Tabs, force = false) {
        const from = this._currentRuleTab;

        if (force || await this._onTabChanging(from, this._currentRuleTab)) {
            this._currentRuleTab = tab;

            const page = this._rulePage[tab];
            await this._setPageTab(page, true);

            this._onTabChanged(from, this._currentRuleTab);
        }
    }


    _isLoading(): boolean {
        return !this._initialized
            || !this._currentTransferRule.isActivated
            || this._currentTransferRule?.isLoading;
    }


    async _onTabChanging(from: number, to: number): Promise<boolean> {
        if (this._currentTransferRule == null
            || !this._currentTransferRule.isModified()) return true;

        const response = await this._promptDialog.confirm(
            this._lgTranslate.translate(".ConfirmChangeTabs.Title"),
            this._lgTranslate.translate(".ConfirmChangeTabs.Body"),
            {
                buttons: [
                    {
                        id: "save",
                        name: this._lgTranslate.translate(".ConfirmChangeTabs.Save"),
                        isConfirmAction: true
                    },
                    {
                        id: "discard",
                        name: this._lgTranslate.translate(".ConfirmChangeTabs.Discard"),
                    },
                    {
                        id: "cancel",
                        name: this._lgTranslate.translate(".ConfirmChangeTabs.Cancel"),
                    },
                ]
            }
        );

        if (response === "cancel") return;

        if (response === "save") {
            return await this._doSave();
        }

        if (response === "discard") {
            this._currentTransferRule.isActivated = false;
            return true;
        }

        return false;
    }


    _onTabChanged(from: number, to: number): void {
        if (!this._initialized) return;

        if (to === Tabs.AccountSplitRules) {
            this._currentTransferRule = this._editAccountSplitRule;

        } else if (to === Tabs.AccountTransferRules) {
            this._currentTransferRule = this._editAccountTransferRule;

        } else if (to === Tabs.AccountToResourceRules) {
            this._currentTransferRule = this._editAccountToResourceRule;

        } else if (to === Tabs.ResourceTransferRules) {
            this._currentTransferRule = this._editResourceTransferRule;

        } else if (to === Tabs.ResourceToTaskRules) {
            this._currentTransferRule = this._editResourceToTaskRule;

        } else if (to === Tabs.ResourceTaskTransferRules) {
            this._currentTransferRule = this._editResourceTaskTransferRule;

        } else if (to === Tabs.ResourceTaskSplitRules) {
            this._currentTransferRule = this._editResourceTaskSplitRule;

        } else if (to === Tabs.ResourceTaskToActivityRules) {
            this._currentTransferRule = this._editResourceTaskToActivityRule;
        }

        this._currentTransferRule.activate();
    }


    // ----------------------------------------------------------------------------------
    // Saving

    _isChanged(): boolean {
        return this._currentTransferRule.isModified();
    }


    private async _doSave(): Promise<boolean> {
        if (this._currentTransferRule.isModified()) {
            const saved = await this._currentTransferRule.save();

            if (!saved) return false;

            this._updatedTabs.add(this._currentTab);

            return true;

            // There was nothing to save, so reloading is not needed
        } else {
            return true;
        }
    }


    async _apply(): Promise<void> {
        await this._doSave();
    }


    async _save(): Promise<boolean> {
        return this._doSave();
        // If _doSave returns true, then dialog-mixin will automatically close the dialog
    }


    _onClose(): void {
        this._resolve({
            updatedTabs: [...this._updatedTabs],
            reload: this._updatedTabs.size > 0
        });
    }
}


@Injectable()
export class EditRulesDialog extends getDialogFactoryBase(EditRulesDialogComponent, "show") {
    constructor() {
        const _factory = inject(LgDialogFactory);

        super(_factory);
    }
}
