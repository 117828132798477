import { InjectionToken } from "@angular/core";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { RulesPivotLevel2 } from "../../rules-base/pivots/rules-pivot.types";
import { TransferRule } from "../gateway/transfer-rules-gateway.type";


export const SOURCE_RULE_FILTER_SELECTOR = new InjectionToken<RuleFilterSelectorService>("SourceRuleFilterSelector");
export const TARGET_RULE_FILTER_SELECTOR = new InjectionToken<RuleFilterSelectorService>("TargetRuleFilterSelector");


export type TransferRulesPivotLevel2<TRule extends TransferRule = TransferRule> = RulesPivotLevel2<TRule> & {
    allowedTargetUids: string[];
};
