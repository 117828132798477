import { IAppDefinitions } from "@shared/app-definitions.types";


export class Filters {
    static CLIENT_LABEL_1 = "clientLabel1Code";
    static CLIENT_LABEL_2 = "clientLabel2Code";
    static CLIENT_LABEL_3 = "clientLabel3Code";

    static PRODUCT_INTERNAL_LABEL_1 = "productInternalLabel1Code";
    static PRODUCT_INTERNAL_LABEL_2 = "productInternalLabel2Code";
    static PRODUCT_INTERNAL_LABEL_3 = "productInternalLabel3Code";

    static ORGANIZATION_LABEL_1 = "organisationLabel1Code";
    static ORGANIZATION_LABEL_2 = "organisationLabel2Code";
    static ORGANIZATION_LABEL_3 = "organisationLabel3Code";
    static ORGANIZATION_LABEL_4 = "organisationLabel4Code";
    static ORGANIZATION_LABEL_5 = "organisationLabel5Code";
    static ORGANIZATION_LABEL_6 = "organisationLabel6Code";
    static ENCOUNTER_ORGANIZATION_LABEL1 = "encounterOrganisationLabel1Code";
    static ENCOUNTER_ORGANIZATION_LABEL2 = "encounterOrganisationLabel2Code";
    static ENCOUNTER_ORGANIZATION_LABEL3 = "encounterOrganisationLabel3Code";
    static ENCOUNTER_ORGANIZATION_LABEL4 = "encounterOrganisationLabel4Code";
    static ENCOUNTER_ORGANIZATION_LABEL5 = "encounterOrganisationLabel5Code";
    static ENCOUNTER_ORGANIZATION_LABEL6 = "encounterOrganisationLabel6Code";

    static SPECIALISM = "specialismCode";
    static REGULATION_SPECIALISM = "regulationSpecialismCode";
    static ENCOUNTER_SPECIALTY = "encounterSpecialtyCode";
    static ENCOUNTER_REGULATION_SPECIALTY = "encounterRegulationSpecialtyCode";
    static ENCOUNTER_REGULATION_SPECIALTY_SOURCE_TYPE = "encounterRegulationSpecialtySystemCode";
    static PRODUCT_SPECIALTY_CODE = "productSpecialtyCode";
    static PRODUCT_REGULATION_SPECIALITY_CODE = "productRegulationSpecialtyCode";
    static PRODUCT_REGULATION_SPECIALITY_SYSTEM_CODE = "productRegulationSpecialtySystemCode";
    static CAREGIVER = "caregiverCode";

    static ENCOUNTER_PRODUCT = "encounterProductCode";
    static ENCOUNTER_REGULATION_PRODUCT = "encounterRegulationProductCode";
    static ENCOUNTER_REGULATION_PRODUCT_SOURCE_TYPE = "encounterRegulationProductSystemCode";
    static ENCOUNTER_REGULATION_LABEL1 = "encounterRegulationProductTopgroupCode";
    static ENCOUNTER_REGULATION_LABEL2 = "encounterRegulationProductGroupCode";
    static PRODUCT = "productCode";
    static PRODUCT_TOPGROUP = "productTopgroupCode";
    static PRODUCT_GROUP = "productGroupCode";
    static PRODUCT_SUBMISSION_TYPE = "productSubmissionTypeId";
    static PRODUCT_TYPE = "productTypeCode";
    static REGULATION_PRODUCT = "regulationProductCode";
    static REGULATION_PRODUCT_SOURCE_TYPE = "regulationProductSystemCode";
    static REGULATION_PRODUCT_TOPGROUP = "regulationProductTopgroupCode";
    static REGULATION_PRODUCT_GROUP = "regulationProductGroupCode";

    static SEGMENT = "segmentCode";
    static REGULATION_SEGMENT = "regulationSegmentCode";
    static ENCOUNTER_SEGMENT = "encounterSegmentCode";
    static ENCOUNTER_REGULATION_SEGMENT = "encounterRegulationSegmentCode";
    static ENCOUNTER_REGULATION_SEGMENT_SOURCE_TYPE = "encounterRegulationSegmentSystemCode";
    static ENCOUNTER_REGULATION_SEGMENT_LABEL_1 = "encounterRegulationSegmentSubgroupCode";
    static PRODUCT_SEGMENT = "productSegmentCode";
    static PRODUCT_REGULATION_SEGMENT = "productRegulationSegmentCode";
    static PRODUCT_REGULATION_SEGMENT_SOURCE_TYPE = "productRegulationSegmentSystemCode";
    static PRODUCT_REGULATION_SEGMENT_LABEL_1 = "productRegulationSegmentSubgroupCode";

    static PAYER = "payerCode";
    static ENCOUNTER_PAYER = "encounterPayerCode";
    static ENCOUNTER_PAYER_SUBGROUP = "encounterPayerSubgroupCode";
    static ENCOUNTER_PAYER_GROUP = "encounterPayerGroupCode";
    static PAYER_SUBGROUP = "payerSubgroupCode";
    static PAYER_GROUP = "payerGroupCode";

    static COUNTRY_LABEL1 = "countryLabel1Code";
    static COUNTRY_LABEL2 = "countryLabel2Code";
    static COUNTRY_LABEL3 = "countryLabel3Code";
    static ENCOUNTER_COUNTRY_LABEL1 = "encounterCountryLabel1Code";
    static ENCOUNTER_COUNTRY_LABEL2 = "encounterCountryLabel2Code";
    static ENCOUNTER_COUNTRY_LABEL3 = "encounterCountryLabel3Code";
    static PRODUCT_COUNTRY_LABEL1 = "productCountryLabel1Code";
    static PRODUCT_COUNTRY_LABEL2 = "productCountryLabel2Code";
    static PRODUCT_COUNTRY_LABEL3 = "productCountryLabel3Code";
    static BENCHMARK_LABEL_1 = "benchmarkLabel1Code";
    static BENCHMARK_LABEL_2 = "benchmarkLabel2Code";
    static BENCHMARK_LABEL_3 = "benchmarkLabel3Code";
    static PRODUCT_BENCHMARK_LABEL_1 = "productBenchmarkLabel1Code";
    static PRODUCT_BENCHMARK_LABEL_2 = "productBenchmarkLabel2Code";
    static PRODUCT_BENCHMARK_LABEL_3 = "productBenchmarkLabel3Code";

    static ENCOUNTER_PROCEDURE = "encounterProcedureCode";
    static ENCOUNTER_REGULATION_PROCEDURE = "encounterRegulationProcedureCode";
    static PRODUCT_PROCEDURE = "productProcedureCode";
    static PRODUCT_REGULATION_PROCEDURE = "productRegulationProcedureCode";

    static END_YEAR = "endYear";
    static END_MONTH = "endMonth";

    static SOURCE_PATIENT = "sourcePatientCode";
    static REGULATION_PATIENT = "regulationPatientCode";
    static SOURCE_PATHWAY = "sourcePathwayCode";
    static AGE = "age";
    static AGE_CATEGORY = "ageCategoryId";
    static GENDER = "genderId";
    static POSTAL_CODE = "postalCode";

    static SOURCE_ENCOUNTER = "sourceEncounterCode";
    static SOURCE_ENCOUNTER_2 = "sourceEncounter2Code";
    static SOURCE_ENCOUNTER_GROUP = "sourceEncounterGroupCode";

    static DIAGNOSIS = "diagnosisCode";
    static REGULATION_DIAGNOSIS = "regulationDiagnosisCode";
    static ENCOUNTER_REGULATION_DIAGNOSIS = "encounterRegulationDiagnosisCode";

    static PRODUCT_DIAGNOSIS = "productDiagnosisCode";
    static ENCOUNTER_DIAGNOSIS = "encounterDiagnosisCode";
    static PRODUCT_REGULATION_DIAGNOSIS = "productRegulationDiagnosisCode";
    static PRODUCT_REGULATION_DIAGNOSIS_LABEL1 = "productRegulationDiagnosisLabel1Code";

    static SOURCE_PRODUCT = "sourceProductCode";
    static SOURCE_PRODUCT_2 = "sourceProduct2Code";
    static SOURCE_PRODUCT_GROUP = "sourceProductGroupCode";
    static SOURCE_TYPE = "sourceTypeCode";

    static PRODUCTION_UNIT_COST_CENTRE_TOPGROUP = "productionUnitCostCentreTopgroupCode";
    static PRODUCTION_UNIT_COST_CENTRE_GROUP = "productionUnitCostCentreGroupCode";
    static PRODUCTION_UNIT_COST_CENTRE_SUBGROUP = "productionUnitCostCentreSubgroupCode";
    static PRODUCTION_UNIT_COST_CENTRE = "productionUnitCostCentreCode";
    static PRODUCTION_UNIT = "productionUnitCode";
    static PRODUCTION_UNIT_TOPGROUP = "productionUnitTopgroupCode";
    static PRODUCTION_UNIT_GROUP = "productionUnitGroupCode";
    static PRODUCTION_UNIT_SUBGROUP = "productionUnitSubgroupCode";
    static PRODUCTION_UNIT_TYPE = "productionUnitTypeId";
    static PRODUCTION_UNIT_BENCHMARK_TYPE = "productionUnitBenchmarkTypeId";
    static PRODUCTION_UNIT_REGULATION_TYPE = "productionUnitRegulationTypeCode";
    static PRODUCTION_UNIT_REGULATION_TYPE_SUBGROUP = "productionUnitRegulationTypeSubgroupCode";
    static PRODUCTION_UNIT_REGULATION_TYPE_LABEL_1 = "productionUnitRegulationTypeLabel1Code";
    static PRODUCTION_UNIT_LEVEL1 = "productionUnitLevel1Code";
    static PRODUCTION_UNIT_LEVEL2 = "productionUnitLevel2Code";
    static PRODUCTION_UNIT_LEVEL3 = "productionUnitLevel3Code";
    static PRODUCTION_UNIT_LEVEL4 = "productionUnitLevel4Code";
    static PRODUCTION_UNIT_LEVEL5 = "productionUnitLevel5Code";

    static ENCOUNTER_PRODUCTION_UNIT_LEVEL1 = "encounterProductionUnitLevel1Code";
    static ENCOUNTER_PRODUCTION_UNIT_LEVEL2 = "encounterProductionUnitLevel2Code";
    static ENCOUNTER_PRODUCTION_UNIT_LEVEL3 = "encounterProductionUnitLevel3Code";
    static ENCOUNTER_PRODUCTION_UNIT_LEVEL4 = "encounterProductionUnitLevel4Code";
    static ENCOUNTER_PRODUCTION_UNIT_LEVEL5 = "encounterProductionUnitLevel5Code";
    static ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE_TOPGROUP = "encounterProductionUnitCostCentreTopgroupCode";
    static ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE_GROUP = "encounterProductionUnitCostCentreGroupCode";
    static ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE_SUBGROUP = "encounterProductionUnitCostCentreSubgroupCode";
    static ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE = "encounterProductionUnitCostCentreCode";
    static ENCOUNTER_PRODUCTION_UNIT_TYPE = "encounterProductionUnitTypeId";
    static ENCOUNTER_PRODUCTION_UNIT = "encounterProductionUnitCode";

    static ACTIVITY = "activityCode";
    static ACTIVITY_GROUP = "activityGroupCode";
    static ACTIVITY_SUBGROUP = "activitySubgroupCode";
    static ACTIVITY_TOPGROUP = "activityTopgroupCode";
    static ACTIVITY_TYPE = "activityTypeId";
    static ACTIVITY_SOURCE_TYPE = "activitySourceTypeCode";
    static REGULATION_ACTIVITY = "regulationActivityCode";
    static REGULATION_ACTIVITY_GROUP = "regulationActivityGroupCode";
    static REGULATION_ACTIVITY_SUBGROUP = "regulationActivitySubgroupCode";
    static REGULATION_ACTIVITY_TOPGROUP = "regulationActivityTopgroupCode";
    static REGULATION_ACTIVITY_SOURCE_TYPE = "regulationActivitySourceTypeCode";
    static ACTIVITY_BENCHMARK_TYPE = "activityBenchmarkTypeId";
    static ACTIVITY_REGULATION_LABEL1 = "activityRegulationLabel1Code";
    static ACTIVITY_REGULATION_LABEL2 = "activityRegulationLabel2Code";
    static ACTIVITY_REGULATION_LABEL3 = "activityRegulationLabel3Code";
    static ACTIVITY_DIMENSION1 = "activityDimension1Code";
    static ACTIVITY_DIMENSION2 = "activityDimension2Code";
    static ACTIVITY_DIMENSION3 = "activityDimension3Code";


    static EXECUTING_SPECIALISM = "executingSpecialismCode";
    static EXECUTING_SPECIALISM_SOURCE_TYPE = "executingSpecialismSourceTypeCode";
    static ACTIVITY_EXECUTING_SPECIALISM = "activityExecutingSpecialismCode";
    static EXECUTING_REGULATION_SPECIALISM = "executingRegulationSpecialismCode";

    static PRODUCTION_TYPE = "productionTypeId";
    static ENCOUNTER_PERIOD_TYPE = "encounterPeriodTypeId";

    static COST_CENTRE = "costCentreCode";
    static COST_CENTRE_TOPGROUP = "costCentreTopgroupCode";
    static COST_CENTRE_GROUP = "costCentreGroupCode";
    static COST_CENTRE_SUBGROUP = "costCentreSubgroupCode";
    static COST_CENTRE_LEVEL1 = "costCentreLevel1Code";
    static COST_CENTRE_LEVEL2 = "costCentreLevel2Code";
    static COST_CENTRE_LEVEL3 = "costCentreLevel3Code";
    static COST_CENTRE_LEVEL4 = "costCentreLevel4Code";
    static COST_CENTRE_LEVEL5 = "costCentreLevel5Code";
    static COST_CENTRE_TYPE = "costCentreTypeId";
    static COST_CENTRE_BENCHMARK_TYPE = "costCentreBenchmarkTypeId";
    static COST_CENTRE_BENCHMARK_GROUP_TYPE = "costCentreBenchmarkTypeGroupId";
    static COST_CENTRE_BENCHMARK_TOPGROUP_TYPE = "costCentreBenchmarkTypeTopgroupId";
    static COST_CENTRE_BENCHMARK_SUBGROUP_TYPE = "costCentreBenchmarkTypeSubgroupId";
    static COST_CENTRE_REGULATION_TYPE = "costCentreRegulationTypeCode";
    static COST_CENTRE_REGULATION_TYPE_SUBGROUP = "costCentreRegulationTypeSubgroupCode";

    static COST_CENTRE_AFTER_TRANSFER = "targetCostCentreCode";
    static COST_CENTRE_TOPGROUP_AFTER_TRANSFER = "targetCostCentreTopgroupCode";
    static COST_CENTRE_GROUP_AFTER_TRANSFER = "targetCostCentreGroupCode";
    static COST_CENTRE_SUBGROUP_AFTER_TRANSFER = "targetCostCentreSubgroupCode";
    static COST_CENTRE_LEVEL1_AFTER_TRANSFER = "targetCostCentreLevel1Code";
    static COST_CENTRE_LEVEL2_AFTER_TRANSFER = "targetCostCentreLevel2Code";
    static COST_CENTRE_LEVEL3_AFTER_TRANSFER = "targetCostCentreLevel3Code";
    static COST_CENTRE_LEVEL4_AFTER_TRANSFER = "targetCostCentreLevel4Code";
    static COST_CENTRE_LEVEL5_AFTER_TRANSFER = "targetCostCentreLevel5Code";
    static COST_CENTRE_TYPE_AFTER_TRANSFER = "targetCostCentreTypeId";
    static COST_CENTRE_BENCHMARK_TYPE_AFTER_TRANSFER = "targetCostCentreBenchmarkTypeId";
    static COST_CENTRE_REGULATION_TYPE_AFTER_TRANSFER = "targetCostCentreRegulationTypeCode";
    static COST_CENTRE_REGULATION_TYPE_SUBGROUP_AFTER_TRANSFER = "targetCostCentreRegulationTypeSubgroupCode";

    static ACCOUNT_REGULATION_TYPE = "accountRegulationTypeCode";
    static ACCOUNT_REGULATION_TYPE_SUBGROUP = "accountRegulationTypeSubgroupCode";
    static ACCOUNT_BENCHMARK_TYPE = "accountBenchmarkTypeId";
    static ACCOUNT_BENCHMARK_TYPE_GROUP = "accountBenchmarkTypeGroupId";
    static ACCOUNT_BENCHMARK_TYPE_SUBGROUP = "accountBenchmarkTypeSubgroupId";
    static ACCOUNT_BENCHMARK_TYPE_TOPGROUP = "accountBenchmarkTypeTopgroupId";

    static COST_CLASSIFICATION = "costClassificationCode";
    static COST_REGULATION_CLASSIFICATION = "costRegulationClassificationCode";
    static COST_BENCHMARK_TYPE = "costBenchmarkTypeId";
    static COST_REGULATION_TYPE = "costRegulationTypeCode";
    static COST_REGULATION_TYPE_LABEL1 = "costRegulationTypeLabel1Code";
    static COST_REGULATION_TYPE_LABEL2 = "costRegulationTypeLabel2Code";
    static COST_REGULATION_TYPE_LABEL3 = "costRegulationTypeLabel3Code";

    static SUBMISSION_RESOURCE = "submissionResourceCode";
    static SUBMISSION_RESOURCE_TOPGROUP = "submissionResourceTopgroupCode";
    static SUBMISSION_RESOURCE_GROUP = "submissionResourceGroupCode";
    static SUBMISSION_RESOURCE_SUBGROUP = "submissionResourceSubgroupCode";
    static RESOURCE_BENCHMARK_TYPE = "resourceBenchmarkTypeCode";
    static RESOURCE_BENCHMARK_TYPE_TOPGROUP = "resourceBenchmarkTypeTopgroupCode";
    static RESOURCE_BENCHMARK_TYPE_GROUP = "resourceBenchmarkTypeGroupCode";
    static RESOURCE_BENCHMARK_TYPE_SUBGROUP = "resourceBenchmarkTypeSubgroupCode";

    static RESOURCE_BENCHMARK_TYPE_AFTER_TRANSFER = "targetResourceBenchmarkTypeCode";
    static RESOURCE_BENCHMARK_TYPE_GROUP_AFTER_TRANSFER = "targetResourceBenchmarkTypeGroupCode";
    static RESOURCE_BENCHMARK_TYPE_SUBGROUP_AFTER_TRANSFER = "targetResourceBenchmarkTypeSubgroupCode";

    static ROOM = "roomCode";

    static ACCOUNT = "accountCode";
    static SUB_ACCOUNT = "subAccountCode";
    static ACCOUNT_TOPGROUP = "accountTopgroupCode";
    static ACCOUNT_GROUP = "accountGroupCode";
    static ACCOUNT_SUBGROUP = "accountSubgroupCode";
    static ACCOUNT_TYPE = "accountTypeId";

    static ACCOUNT_DIMENSION_1 = "dimension1Code";
    static ACCOUNT_DIMENSION_1_REGULATION_TYPE = "dimension1RegulationTypeCode";
    static ACCOUNT_DIMENSION_2 = "dimension2Code";
    static ACCOUNT_DIMENSION_3 = "dimension3Code";
    static ACCOUNT_DIMENSION_4 = "dimension4Code";
    static ACCOUNT_DIMENSION_5 = "dimension5Code";
    static ACCOUNT_DIMENSION_6 = "dimension6Code";
    static ACCOUNT_DIMENSION = "dimensionCode";
    static ACCOUNT_DIMENSION_GROUP = "dimensionGroupCode";


    static FINANCIAL_STATEMENT_CATEGORY = "financialStatementCategoryCode";
    static FINANCIAL_STATEMENT_GROUP = "financialStatementGroupCode";

    static JOB_TITLE = "jobTitleCode";
    static JOB_TITLE_GROUP = "jobTitleGroupCode";
    static JOB_TITLE_TYPE = "jobTitleTypeId";
    static JOB_TITLE_BENCHMARK_TYPE = "jobTitleBenchmarkTypeId";
    static JOB_TITLE_BENCHMARK_TYPE_SUBGROUPS = "jobTitleBenchmarkTypeSubgroupsId";
    static JOB_TITLE_BENCHMARK_TYPE_TOPGROUPS = "jobTitleBenchmarkTypeTopgroupsId";
    static JOB_TITLE_REGULATION_TYPE = "jobTitleRegulationTypeCode";
    static JOB_TITLE_REGULATION_TYPE_SUBGROUP = "jobTitleRegulationTypeSubgroupCode";

    static EMPLOYEE = "employeeCode";

    static MACHINE = "machineCode";
    static MACHINE_TYPE = "machineTypeId";
    static MACHINE_COMPONENT = "componentCode";

    static MATERIAL = "materialCode";
    static MATERIAL_TYPE = "materialTypeId";

    static RESOURCE = "resourceCode";
    static RESOURCE_ROOT = "resourceRootCode";
    static RESOURCE_TYPE = "resourceTypeCode";

    static REGULATION_RESOURCE = "regulationResourceCode";
    static REGULATION_RESOURCE_TOPGROUP = "regulationResourceTopgroupCode";
    static REGULATION_RESOURCE_GROUP = "regulationResourceGroupCode";
    static REGULATION_RESOURCE_SUBGROUP = "regulationResourceSubgroupCode";
    static REGULATION_RESOURCE_DEFAULT_SOURCE = "regulationResourceDefaultSourceId";

    static RESOURCE_AFTER_TRANSFER = "targetResourceCode";
    static RESOURCE_ROOT_AFTER_TRANSFER = "targetResourceRootCode";
    static RESOURCE_TYPE_AFTER_TRANSFER = "targetResourceTypeCode";

    static REGULATION_RESOURCE_AFTER_TRANSFER = "targetRegulationResourceCode";
    static REGULATION_RESOURCE_TOPGROUP_AFTER_TRANSFER = "targetRegulationResourceTopgroupCode";
    static REGULATION_RESOURCE_GROUP_AFTER_TRANSFER = "targetRegulationResourceGroupCode";
    static REGULATION_RESOURCE_SUBGROUP_AFTER_TRANSFER = "targetRegulationResourceSubgroupCode";
    static REGULATION_RESOURCE_DEFAULT_SOURCE_AFTER_TRANSFER = "targetRegulationResourceDefaultSourceId";

    static SUBMISSION_TASK = "submissionTaskCode";
    static SUBMISSION_TASK_TOPGROUP = "submissionTaskTopgroupCode";
    static SUBMISSION_TASK_GROUP = "submissionTaskGroupCode";
    static SUBMISSION_TASK_SUBGROUP = "submissionTaskSubgroupCode";
    static SUBMISSION_TYPE = "submissionTypeCode";
    static SUBMISSION_SUBTYPE = "submissionSubtypeCode";
    static REGULATION_LABEL1_CODE = "regulationLabel1Code";
    static REGULATION_LABEL2_CODE = "regulationLabel2Code";
    static REGULATION_LABEL3_CODE = "regulationLabel3Code";

    static IS_DIRECT = "isDirect";

    static TASK = "taskCode";
    static SUBTASK = "subtaskCode";
    static REGULATION_TASK = "regulationTaskCode";
    static REGULATION_TASK_SUBGROUP = "regulationTaskSubgroupCode";

    static TASK_AFTER_TRANSFER = "targetTaskCode";
    static REGULATION_TASK_AFTER_TRANSFER = "targetRegulationTaskCode";

    static TRANSFER_TYPE = "transferTypeId";

    static ADJUSTMENT_TYPE = "adjustmentTypeId";
    static TRANSFER_METHOD = "transferMethodId";
    static COST_DRIVER = "costDriverId";
    static TRANSFER_COST_DRIVER = "transferCostDriverId";

    static DATA_ERRORS = "errorId";
    static NON_ZERO_ONLY = "nonZeroOnly";
    static ONLY_ERRORS = "onlyErrors";

    static REFERENCE_SOURCE = "referenceSourceId";

    static RULE = "ruleId";
    static RULE_GROUP = "ruleGroupId";
    static RULE_TYPE = "ruleTypeId";

    static EXCLUSION_GROUP = "exclusionGroupCode";

    static ACCOUNT_ALLOCATION_COST_DRIVER = "accountAllocationCostDriverId";
    static ACCOUNT_ALLOCATION_RULE_ID = "accountAllocationRuleId";
    static ACCOUNT_ALLOCATION_RULE_TYPE_ID = "accountAllocationRuleTypeId";
    static ACCOUNT_ALLOCATION_RULE_GROUP_ID = "accountAllocationRuleGroupId";
    static ACCOUNT_ALLOCATION_TRANSFER_TYPE = "accountAllocationTransferTypeId";
    static RESOURCE_ALLOCATION_RULE_TYPE = "resourceAllocationRuleTypeId";
    static RESOURCE_ALLOCATION_RULE_GROUP = "resourceAllocationRuleGroupId";
    static RESOURCE_ALLOCATION_RULE = "resourceAllocationRuleId";
    static SUPPORT_COST_ALLOCATION_RULE_TYPE = "supportCostAllocationRuleTypeId";
    static SUPPORT_COST_ALLOCATION_RULE_GROUP = "supportCostAllocationRuleGroupId";
    static SUPPORT_COST_ALLOCATION_RULE = "supportCostAllocationRuleId";

}

// ----------------------------------------------------------------------------------
// Filter layout

export interface FiltersLayoutGroup {
    lc: string;
    filters: FiltersLayoutFilter[];
}

export interface FiltersLayoutFilter {
    filter: string;
    lc: string;
    hidden?: boolean;
}


export const FILTERS_LAYOUT: FiltersLayoutGroup[] = [
    {
        lc: "APP._FilterGroups.ClientLabels",
        filters: [
            { lc: "APP._Filters.ClientLabel1", filter: Filters.CLIENT_LABEL_1 },
            { lc: "APP._Filters.ClientLabel2", filter: Filters.CLIENT_LABEL_2 },
            { lc: "APP._Filters.ClientLabel3", filter: Filters.CLIENT_LABEL_3 },
        ],
    },
    {
        lc: "APP._FilterGroups.ProductInternalLabels",
        filters: [
            { lc: "APP._Filters.ProductInternalLabel1", filter: Filters.PRODUCT_INTERNAL_LABEL_1 },
            { lc: "APP._Filters.ProductInternalLabel2", filter: Filters.PRODUCT_INTERNAL_LABEL_2 },
            { lc: "APP._Filters.ProductInternalLabel3", filter: Filters.PRODUCT_INTERNAL_LABEL_3 },
        ],
    },
    {
        lc: "APP._FilterGroups.OrganizationLabels",
        filters: [
            { lc: "APP._Filters.ProductOrganizationLabel1", filter: Filters.ORGANIZATION_LABEL_1 },
            { lc: "APP._Filters.ProductOrganizationLabel2", filter: Filters.ORGANIZATION_LABEL_2 },
            { lc: "APP._Filters.ProductOrganizationLabel3", filter: Filters.ORGANIZATION_LABEL_3 },
            { lc: "APP._Filters.ProductOrganizationLabel4", filter: Filters.ORGANIZATION_LABEL_4 },
            { lc: "APP._Filters.ProductOrganizationLabel5", filter: Filters.ORGANIZATION_LABEL_5 },
            { lc: "APP._Filters.ProductOrganizationLabel6", filter: Filters.ORGANIZATION_LABEL_6 },
            { lc: "APP._Filters.EncounterOrganizationLabel1", filter: Filters.ENCOUNTER_ORGANIZATION_LABEL1 },
            { lc: "APP._Filters.EncounterOrganizationLabel2", filter: Filters.ENCOUNTER_ORGANIZATION_LABEL2 },
            { lc: "APP._Filters.EncounterOrganizationLabel3", filter: Filters.ENCOUNTER_ORGANIZATION_LABEL3 },
            { lc: "APP._Filters.EncounterOrganizationLabel4", filter: Filters.ENCOUNTER_ORGANIZATION_LABEL4 },
            { lc: "APP._Filters.EncounterOrganizationLabel5", filter: Filters.ENCOUNTER_ORGANIZATION_LABEL5 },
            { lc: "APP._Filters.EncounterOrganizationLabel6", filter: Filters.ENCOUNTER_ORGANIZATION_LABEL6 },
        ],
    },
    {
        lc: "APP._FilterGroups.CountryLabels",
        filters: [
            { lc: "APP._Fields.CountryLabel1", filter: Filters.COUNTRY_LABEL1 },
            { lc: "APP._Fields.CountryLabel2", filter: Filters.COUNTRY_LABEL2 },
            { lc: "APP._Fields.CountryLabel3", filter: Filters.COUNTRY_LABEL3 },
            { lc: "APP._Filters.EncounterCountryLabel1", filter: Filters.ENCOUNTER_COUNTRY_LABEL1 },
            { lc: "APP._Filters.EncounterCountryLabel2", filter: Filters.ENCOUNTER_COUNTRY_LABEL2 },
            { lc: "APP._Filters.EncounterCountryLabel3", filter: Filters.ENCOUNTER_COUNTRY_LABEL3 },
            { lc: "APP._Filters.ProductCountryLabel1", filter: Filters.PRODUCT_COUNTRY_LABEL1 },
            { lc: "APP._Filters.ProductCountryLabel2", filter: Filters.PRODUCT_COUNTRY_LABEL2 },
            { lc: "APP._Filters.ProductCountryLabel3", filter: Filters.PRODUCT_COUNTRY_LABEL3 },
        ],
    },
    {
        lc: "APP._FilterGroups.BenchmarkLabels",
        filters: [
            { lc: "APP._Fields.BenchmarkLabel1", filter: Filters.BENCHMARK_LABEL_1 },
            { lc: "APP._Fields.BenchmarkLabel2", filter: Filters.BENCHMARK_LABEL_2 },
            { lc: "APP._Fields.BenchmarkLabel3", filter: Filters.BENCHMARK_LABEL_3 },
            { lc: "APP._Fields.ProductBenchmarkLabel1", filter: Filters.PRODUCT_BENCHMARK_LABEL_1 },
            { lc: "APP._Fields.ProductBenchmarkLabel2", filter: Filters.PRODUCT_BENCHMARK_LABEL_2 },
            { lc: "APP._Fields.ProductBenchmarkLabel3", filter: Filters.PRODUCT_BENCHMARK_LABEL_3 },
        ],
    },
    {
        lc: "APP._FilterGroups.Segment",
        filters: [
            { lc: "APP._Filters.Segment", filter: Filters.SEGMENT },
            { lc: "APP._Filters.RegulationSegment", filter: Filters.REGULATION_SEGMENT },
            { lc: "APP._Filters.EncounterSegment", filter: Filters.ENCOUNTER_SEGMENT },
            { lc: "APP._Filters.EncounterRegulationSegment", filter: Filters.ENCOUNTER_REGULATION_SEGMENT },
            { lc: "APP._Filters.EncounterRegulationSegmentSourceType", filter: Filters.ENCOUNTER_REGULATION_SEGMENT_SOURCE_TYPE },
            { lc: "APP._Filters.EncounterRegulationSegmentLabel1", filter: Filters.ENCOUNTER_REGULATION_SEGMENT_LABEL_1 },
            { lc: "APP._Filters.ProductSegment", filter: Filters.PRODUCT_SEGMENT },
            { lc: "APP._Filters.ProductRegulationSegment", filter: Filters.PRODUCT_REGULATION_SEGMENT },
            { lc: "APP._Filters.ProductRegulationSegmentSourceType", filter: Filters.PRODUCT_REGULATION_SEGMENT_SOURCE_TYPE },
            { lc: "APP._Filters.ProductRegulationSegmentLabel1", filter: Filters.PRODUCT_REGULATION_SEGMENT_LABEL_1 },
        ],
    },
    {
        lc: "APP._FilterGroups.ExecutingSpecialism",
        filters: [
            { lc: "APP._Filters.Specialism", filter: Filters.SPECIALISM },
            { lc: "APP._Filters.RegulationSpecialism", filter: Filters.REGULATION_SPECIALISM },
            { lc: "APP._Filters.EncounterSpecialty", filter: Filters.ENCOUNTER_SPECIALTY },
            { lc: "APP._Filters.EncounterRegulationSpecialty", filter: Filters.ENCOUNTER_REGULATION_SPECIALTY },
            { lc: "APP._Filters.EncounterRegulationSpecialtySourceType", filter: Filters.ENCOUNTER_REGULATION_SPECIALTY_SOURCE_TYPE },
            { lc: "APP._Filters.ProductSpecialty", filter: Filters.PRODUCT_SPECIALTY_CODE },
            { lc: "APP._Filters.ProductRegulationSpecialty", filter: Filters.PRODUCT_REGULATION_SPECIALITY_CODE },
            { lc: "APP._Filters.ProductRegulationSpecialtySourceType", filter: Filters.PRODUCT_REGULATION_SPECIALITY_SYSTEM_CODE },
            { lc: "APP._Filters.Caregiver", filter: Filters.CAREGIVER },
            { lc: "APP._Filters.ExecutingSpecialism", filter: Filters.EXECUTING_SPECIALISM },
            { lc: "APP._Filters.ExecutingSpecialismSourceType", filter: Filters.EXECUTING_SPECIALISM_SOURCE_TYPE},
            { lc: "APP._Filters.ExecutingSpecialism", filter: Filters.ACTIVITY_EXECUTING_SPECIALISM },
            { lc: "APP._Filters.ExecutingRegulationSpecialism", filter: Filters.EXECUTING_REGULATION_SPECIALISM },
        ],
    },
    {
        lc: "APP._FilterGroups.Product",
        filters: [
            { lc: "APP._Filters.EncounterProduct", filter: Filters.ENCOUNTER_PRODUCT },
            { lc: "APP._Filters.EncounterRegulationProduct", filter: Filters.ENCOUNTER_REGULATION_PRODUCT },
            { lc: "APP._Filters.EncounterRegulationProductSourceType", filter: Filters.ENCOUNTER_REGULATION_PRODUCT_SOURCE_TYPE },
            { lc: "APP._Filters.EncounterRegulationLabel1", filter: Filters.ENCOUNTER_REGULATION_LABEL1 },
            { lc: "APP._Filters.EncounterRegulationLabel2", filter: Filters.ENCOUNTER_REGULATION_LABEL2 },
            { lc: "APP._Filters.Product", filter: Filters.PRODUCT },
            { lc: "APP._Filters.ProductTopgroup", filter: Filters.PRODUCT_TOPGROUP },
            { lc: "APP._Filters.ProductGroup", filter: Filters.PRODUCT_GROUP },
            { lc: "APP._Filters.ProductSubmissionType", filter: Filters.PRODUCT_SUBMISSION_TYPE },
            { lc: "APP._Filters.ProductType", filter: Filters.PRODUCT_TYPE },
            { lc: "APP._Filters.RegulationProduct", filter: Filters.REGULATION_PRODUCT },
            { lc: "APP._Filters.RegulationProductSourceType", filter: Filters.REGULATION_PRODUCT_SOURCE_TYPE },
            { lc: "APP._Filters.RegulationProductTopgroup", filter: Filters.REGULATION_PRODUCT_TOPGROUP },
            { lc: "APP._Filters.RegulationProductGroup", filter: Filters.REGULATION_PRODUCT_GROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.Procedure",
        filters: [
            { lc: "APP._Filters.ProductProcedure", filter: Filters.PRODUCT_PROCEDURE },
            { lc: "APP._Filters.ProductRegulationProcedure", filter: Filters.PRODUCT_REGULATION_PROCEDURE },
            { lc: "APP._Filters.EncounterProcedure", filter: Filters.ENCOUNTER_PROCEDURE },
            { lc: "APP._Filters.EncounterRegulationProcedure", filter: Filters.ENCOUNTER_REGULATION_PROCEDURE },
        ],
    },
    {
        lc: "APP._FilterGroups.Payer",
        filters: [
            { lc: "APP._Filters.Payer", filter: Filters.PAYER },
            { lc: "APP._Filters.PayerGroup", filter: Filters.PAYER_GROUP },
            { lc: "APP._Filters.PayerSubgroup", filter: Filters.PAYER_SUBGROUP },
            { lc: "APP._Filters.EncounterPayer", filter: Filters.ENCOUNTER_PAYER },
            { lc: "APP._Filters.PayerGroup", filter: Filters.ENCOUNTER_PAYER_GROUP },
            { lc: "APP._Filters.PayerSubgroup", filter: Filters.ENCOUNTER_PAYER_SUBGROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.PatientCharacteristics",
        filters: [
            { lc: "APP._Filters.SourcePatient", filter: Filters.SOURCE_PATIENT },
            { lc: "APP._Filters.RegulationPatient", filter: Filters.REGULATION_PATIENT },
            { lc: "APP._Filters.SourcePathway", filter: Filters.SOURCE_PATHWAY },
            { lc: "APP._Filters.Age", filter: Filters.AGE },
            { lc: "APP._Filters.AgeCategory", filter: Filters.AGE_CATEGORY },
            { lc: "APP._Filters.Gender", filter: Filters.GENDER },
            { lc: "APP._Filters.PostalCode", filter: Filters.POSTAL_CODE },
        ],
    },
    {
        lc: "APP._FilterGroups.EncounterCharacteristics",
        filters: [
            { lc: "APP._Filters.SourceEncounter", filter: Filters.SOURCE_ENCOUNTER },
            { lc: "APP._Filters.SourceEncounter2", filter: Filters.SOURCE_ENCOUNTER_2 },
            { lc: "APP._Filters.SourceEncounterGroup", filter: Filters.SOURCE_ENCOUNTER_GROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.ProductCharacteristics",
        filters: [
            { lc: "APP._Filters.SourceProduct", filter: Filters.SOURCE_PRODUCT },
            { lc: "APP._Filters.SourceProduct2", filter: Filters.SOURCE_PRODUCT_2 },
            { lc: "APP._Filters.SourceProductGroup", filter: Filters.SOURCE_PRODUCT_GROUP },
            { lc: "APP._Filters.SourceType", filter: Filters.SOURCE_TYPE },
        ],
    },
    {
        lc: "APP._FilterGroups.Diagnosis",
        filters: [
            { lc: "APP._Filters.Diagnosis", filter: Filters.DIAGNOSIS },
            { lc: "APP._Filters.RegulationDiagnosis", filter: Filters.REGULATION_DIAGNOSIS },
            { lc: "APP._Filters.EncounterRegulationDiagnosis", filter: Filters.ENCOUNTER_REGULATION_DIAGNOSIS },
            { lc: "APP._Filters.ProductDiagnosis", filter: Filters.PRODUCT_DIAGNOSIS },
            { lc: "APP._Filters.EncounterDiagnosis", filter: Filters.ENCOUNTER_DIAGNOSIS },
            { lc: "APP._Filters.ProductRegulationDiagnosis", filter: Filters.PRODUCT_REGULATION_DIAGNOSIS },
            { lc: "APP._Filters.ProductRegulationDiagnosisLabel1", filter: Filters.PRODUCT_REGULATION_DIAGNOSIS_LABEL1 },
        ],
    },
    {
        lc: "APP._FilterGroups.ProductionUnit",
        filters: [
            { lc: "APP._Filters.ProductionUnit", filter: Filters.PRODUCTION_UNIT },
            { lc: "APP._Filters.ProductionUnitTopgroup", filter: Filters.PRODUCTION_UNIT_TOPGROUP },
            { lc: "APP._Filters.ProductionUnitGroup", filter: Filters.PRODUCTION_UNIT_GROUP },
            { lc: "APP._Filters.ProductionUnitSubgroup", filter: Filters.PRODUCTION_UNIT_SUBGROUP },
            { lc: "APP._Filters.ProductionUnitCostCentreTopgroup", filter: Filters.PRODUCTION_UNIT_COST_CENTRE_TOPGROUP },
            { lc: "APP._Filters.ProductionUnitCostCentreGroup", filter: Filters.PRODUCTION_UNIT_COST_CENTRE_GROUP },
            { lc: "APP._Filters.ProductionUnitCostCentreSubgroup", filter: Filters.PRODUCTION_UNIT_COST_CENTRE_SUBGROUP },
            { lc: "APP._Filters.ProductionUnitCostCentre", filter: Filters.PRODUCTION_UNIT_COST_CENTRE },
            { lc: "APP._Filters.ProductionUnitLevel1", filter: Filters.PRODUCTION_UNIT_LEVEL1 },
            { lc: "APP._Filters.ProductionUnitLevel2", filter: Filters.PRODUCTION_UNIT_LEVEL2 },
            { lc: "APP._Filters.ProductionUnitLevel3", filter: Filters.PRODUCTION_UNIT_LEVEL3 },
            { lc: "APP._Filters.ProductionUnitLevel4", filter: Filters.PRODUCTION_UNIT_LEVEL4 },
            { lc: "APP._Filters.ProductionUnitLevel5", filter: Filters.PRODUCTION_UNIT_LEVEL5 },
            { lc: "APP._Filters.ProductionUnitRegulationTypeLabel1", filter: Filters.PRODUCTION_UNIT_REGULATION_TYPE_LABEL_1 },
            { lc: "APP._Filters.ProductionUnitType", filter: Filters.PRODUCTION_UNIT_TYPE },
            { lc: "APP._Filters.ProductionUnitBenchmarkType", filter: Filters.PRODUCTION_UNIT_BENCHMARK_TYPE },
            { lc: "APP._Filters.ProductionUnitRegulationType", filter: Filters.PRODUCTION_UNIT_REGULATION_TYPE },
            { lc: "APP._Filters.ProductionUnitRegulationTypeSubgroup", filter: Filters.PRODUCTION_UNIT_REGULATION_TYPE_SUBGROUP },
            { lc: "APP._Filters.ProductionUnit", filter: Filters.ENCOUNTER_PRODUCTION_UNIT},
            { lc: "APP._Filters.EncounterProductionUnitCostCentreTopgroup", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE_TOPGROUP },
            { lc: "APP._Filters.EncounterProductionUnitCostCentreGroup", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE_GROUP },
            { lc: "APP._Filters.EncounterProductionUnitCostCentreSubgroup", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE_SUBGROUP },
            { lc: "APP._Filters.EncounterProductionUnitCostCentre", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_COST_CENTRE },
            { lc: "APP._Filters.EncounterProductionUnitLevel1", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_LEVEL1 },
            { lc: "APP._Filters.EncounterProductionUnitLevel2", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_LEVEL2 },
            { lc: "APP._Filters.EncounterProductionUnitLevel3", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_LEVEL3 },
            { lc: "APP._Filters.EncounterProductionUnitLevel4", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_LEVEL4 },
            { lc: "APP._Filters.EncounterProductionUnitLevel5", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_LEVEL5 },
            { lc: "APP._Filters.EncounterProductionUnitType", filter: Filters.ENCOUNTER_PRODUCTION_UNIT_TYPE },
        ],
    },
    {
        lc: "APP._FilterGroups.Activity",
        filters: [
            { lc: "APP._Filters.Activity", filter: Filters.ACTIVITY },
            { lc: "APP._Filters.ActivitySourceType", filter: Filters.ACTIVITY_SOURCE_TYPE },
            { lc: "APP._Filters.ActivityTopgroup", filter: Filters.ACTIVITY_TOPGROUP },
            { lc: "APP._Filters.ActivityGroup", filter: Filters.ACTIVITY_GROUP },
            { lc: "APP._Filters.ActivitySubgroup", filter: Filters.ACTIVITY_SUBGROUP },
            { lc: "APP._Filters.ActivityType", filter: Filters.ACTIVITY_TYPE },
            { lc: "APP._Filters.RegulationActivity", filter: Filters.REGULATION_ACTIVITY },
            { lc: "APP._Filters.RegulationActivitySourceType", filter: Filters.REGULATION_ACTIVITY_SOURCE_TYPE },
            { lc: "APP._Filters.RegulationActivityTopgroup", filter: Filters.REGULATION_ACTIVITY_TOPGROUP },
            { lc: "APP._Filters.RegulationActivityGroup", filter: Filters.REGULATION_ACTIVITY_GROUP },
            { lc: "APP._Filters.RegulationActivitySubgroup", filter: Filters.REGULATION_ACTIVITY_SUBGROUP },
            { lc: "APP._Filters.ActivityBenchmarkType", filter: Filters.ACTIVITY_BENCHMARK_TYPE },
            { lc: "APP._Filters.ActivityRegulationLabel1", filter: Filters.ACTIVITY_REGULATION_LABEL1 },
            { lc: "APP._Filters.ActivityRegulationLabel2", filter: Filters.ACTIVITY_REGULATION_LABEL2 },
            { lc: "APP._Filters.ActivityRegulationLabel3", filter: Filters.ACTIVITY_REGULATION_LABEL3 },
        ],
    },
    {
        lc: "APP._FilterGroups.ActivityDimensions",
        filters: [
            { lc: "APP._Filters.ActivityDimension1", filter: Filters.ACTIVITY_DIMENSION1 },
            { lc: "APP._Filters.ActivityDimension2", filter: Filters.ACTIVITY_DIMENSION2 },
            { lc: "APP._Filters.ActivityDimension3", filter: Filters.ACTIVITY_DIMENSION3 },
        ],
    },
    {
        lc: "APP._FilterGroups.Period",
        filters: [
            { lc: "APP._Filters.EncounterPeriodType", filter: Filters.ENCOUNTER_PERIOD_TYPE },
            { lc: "APP._Filters.ProductionType", filter: Filters.PRODUCTION_TYPE },
            { lc: "APP._Filters.SubmissionType", filter: Filters.SUBMISSION_TYPE },
            { lc: "APP._Filters.SubmissionSubtype", filter: Filters.SUBMISSION_SUBTYPE },
            { lc: "APP._Filters.RegulationLabel1", filter: Filters.REGULATION_LABEL1_CODE },
            { lc: "APP._Filters.RegulationLabel2", filter: Filters.REGULATION_LABEL2_CODE },
            { lc: "APP._Filters.RegulationLabel3", filter: Filters.REGULATION_LABEL3_CODE },
            { lc: "APP._Filters.EndYear", filter: Filters.END_YEAR },
            { lc: "APP._Filters.EndMonth", filter: Filters.END_MONTH },
        ],
    },
    {
        lc: "APP._FilterGroups.CostCentre",
        filters: [
            { lc: "APP._Filters.CostCentre", filter: Filters.COST_CENTRE },
            { lc: "APP._Filters.CostCentreTopgroup", filter: Filters.COST_CENTRE_TOPGROUP },
            { lc: "APP._Filters.CostCentreGroup", filter: Filters.COST_CENTRE_GROUP },
            { lc: "APP._Filters.CostCentreSubgroup", filter: Filters.COST_CENTRE_SUBGROUP },
            { lc: "APP._Filters.CostCentreLevel1", filter: Filters.COST_CENTRE_LEVEL1 },
            { lc: "APP._Filters.CostCentreLevel2", filter: Filters.COST_CENTRE_LEVEL2 },
            { lc: "APP._Filters.CostCentreLevel3", filter: Filters.COST_CENTRE_LEVEL3 },
            { lc: "APP._Filters.CostCentreLevel4", filter: Filters.COST_CENTRE_LEVEL4 },
            { lc: "APP._Filters.CostCentreLevel5", filter: Filters.COST_CENTRE_LEVEL5 },
            { lc: "APP._Filters.CostCentreType", filter: Filters.COST_CENTRE_TYPE },
            { lc: "APP._Filters.CostCentreBenchmarkType", filter: Filters.COST_CENTRE_BENCHMARK_TYPE },
            { lc: "APP._Filters.CostCentreBenchmarkTypeTopgroup", filter: Filters.COST_CENTRE_BENCHMARK_TOPGROUP_TYPE },
            { lc: "APP._Filters.CostCentreBenchmarkTypeGroup", filter: Filters.COST_CENTRE_BENCHMARK_GROUP_TYPE },
            { lc: "APP._Filters.CostCentreBenchmarkTypeSubgroup", filter: Filters.COST_CENTRE_BENCHMARK_SUBGROUP_TYPE },
            { lc: "APP._Filters.CostCentreRegulationType", filter: Filters.COST_CENTRE_REGULATION_TYPE },
            { lc: "APP._Filters.CostCentreRegulationTypeSubgroup", filter: Filters.COST_CENTRE_REGULATION_TYPE_SUBGROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.CostCentreAfterTransfer",
        filters: [
            { lc: "APP._Filters.CostCentreAfterTransfer", filter: Filters.COST_CENTRE_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreTopgroupAfterTransfer", filter: Filters.COST_CENTRE_TOPGROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreGroupAfterTransfer", filter: Filters.COST_CENTRE_GROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreSubgroupAfterTransfer", filter: Filters.COST_CENTRE_SUBGROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreLevel1AfterTransfer", filter: Filters.COST_CENTRE_LEVEL1_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreLevel2AfterTransfer", filter: Filters.COST_CENTRE_LEVEL2_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreLevel3AfterTransfer", filter: Filters.COST_CENTRE_LEVEL3_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreLevel4AfterTransfer", filter: Filters.COST_CENTRE_LEVEL4_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreLevel5AfterTransfer", filter: Filters.COST_CENTRE_LEVEL5_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreTypeAfterTransfer", filter: Filters.COST_CENTRE_TYPE_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreBenchmarkTypeAfterTransfer", filter: Filters.COST_CENTRE_BENCHMARK_TYPE_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreRegulationTypeAfterTransfer", filter: Filters.COST_CENTRE_REGULATION_TYPE_AFTER_TRANSFER },
            { lc: "APP._Filters.CostCentreRegulationTypeSubgroupAfterTransfer", filter: Filters.COST_CENTRE_REGULATION_TYPE_SUBGROUP_AFTER_TRANSFER },
        ],
    },
    {
        lc: "APP._FilterGroups.Account",
        filters: [
            { lc: "APP._Filters.Account", filter: Filters.ACCOUNT },
            { lc: "APP._Filters.SubAccount", filter: Filters.SUB_ACCOUNT },
            { lc: "APP._Filters.AccountTopgroup", filter: Filters.ACCOUNT_TOPGROUP },
            { lc: "APP._Filters.AccountGroup", filter: Filters.ACCOUNT_GROUP },
            { lc: "APP._Filters.AccountSubgroup", filter: Filters.ACCOUNT_SUBGROUP },
            { lc: "APP._Filters.AccountType", filter: Filters.ACCOUNT_TYPE },
            { lc: "APP._Filters.AccountBenchmarkType", filter: Filters.ACCOUNT_BENCHMARK_TYPE },
            { lc: "APP._Filters.AccountBenchmarkTypeTopgroup", filter: Filters.ACCOUNT_BENCHMARK_TYPE_TOPGROUP },
            { lc: "APP._Filters.AccountBenchmarkTypeGroup", filter: Filters.ACCOUNT_BENCHMARK_TYPE_GROUP },
            { lc: "APP._Filters.AccountBenchmarkTypeSubgroup", filter: Filters.ACCOUNT_BENCHMARK_TYPE_SUBGROUP },
            { lc: "APP._Filters.AccountRegulationType", filter: Filters.ACCOUNT_REGULATION_TYPE },
            { lc: "APP._Filters.AccountRegulationTypeSubgroup", filter: Filters.ACCOUNT_REGULATION_TYPE_SUBGROUP },
            { lc: "APP._Filters.FinancialStatementCategory", filter: Filters.FINANCIAL_STATEMENT_CATEGORY },
            { lc: "APP._Filters.FinancialStatementGroup", filter: Filters.FINANCIAL_STATEMENT_GROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.JobTitle",
        filters: [
            { lc: "APP._Filters.JobTitle", filter: Filters.JOB_TITLE },
            { lc: "APP._Filters.JobTitleGroup", filter: Filters.JOB_TITLE_GROUP },
            { lc: "APP._Filters.JobTitleType", filter: Filters.JOB_TITLE_TYPE },
            { lc: "APP._Filters.JobTitleBenchmarkType", filter: Filters.JOB_TITLE_BENCHMARK_TYPE },
            { lc: "APP._Filters.JobTitleBenchmarkTypeSubgroups", filter: Filters.JOB_TITLE_BENCHMARK_TYPE_SUBGROUPS },
            { lc: "APP._Filters.JobTitleBenchmarkTypeTopgroups", filter: Filters.JOB_TITLE_BENCHMARK_TYPE_TOPGROUPS },
            { lc: "APP._Filters.JobTitleRegulationType", filter: Filters.JOB_TITLE_REGULATION_TYPE },
            { lc: "APP._Filters.JobTitleRegulationTypeSubgroup", filter: Filters.JOB_TITLE_REGULATION_TYPE_SUBGROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.CostType",
        filters: [
            { lc: "APP._Filters.CostRegulationType", filter: Filters.COST_REGULATION_TYPE },
            { lc: "APP._Filters.CostRegulationTypeLabel1", filter: Filters.COST_REGULATION_TYPE_LABEL1 },
            { lc: "APP._Filters.CostRegulationTypeLabel2", filter: Filters.COST_REGULATION_TYPE_LABEL2 },
            { lc: "APP._Filters.CostRegulationTypeLabel3", filter: Filters.COST_REGULATION_TYPE_LABEL3 },
            { lc: "APP._Filters.CostClassification", filter: Filters.COST_CLASSIFICATION },
            { lc: "APP._Filters.CostRegulationClassification", filter: Filters.COST_REGULATION_CLASSIFICATION },
            { lc: "APP._Filters.CostBenchmarkType", filter: Filters.COST_BENCHMARK_TYPE },
        ],
    },
    {
        lc: "APP._FilterGroups.Room",
        filters: [
            { lc: "APP._Filters.Room", filter: Filters.ROOM },
        ],
    },
    {
        lc: "APP._FilterGroups.AccountDimensions",
        filters: [
            { lc: "APP._Filters.AccountDimension1", filter: Filters.ACCOUNT_DIMENSION_1 },
            { lc: "APP._Filters.AccountDimension1RegulationType", filter: Filters.ACCOUNT_DIMENSION_1_REGULATION_TYPE },
            { lc: "APP._Filters.AccountDimension2", filter: Filters.ACCOUNT_DIMENSION_2 },
            { lc: "APP._Filters.AccountDimension3", filter: Filters.ACCOUNT_DIMENSION_3 },
            { lc: "APP._Filters.AccountDimension4", filter: Filters.ACCOUNT_DIMENSION_4 },
            { lc: "APP._Filters.AccountDimension5", filter: Filters.ACCOUNT_DIMENSION_5 },
            { lc: "APP._Filters.AccountDimension6", filter: Filters.ACCOUNT_DIMENSION_6 },
            { lc: "APP._Filters.AccountDimension", filter: Filters.ACCOUNT_DIMENSION },
            { lc: "APP._Filters.AccountDimensionGroup", filter: Filters.ACCOUNT_DIMENSION_GROUP }
        ]
    },
    {
        lc: "APP._FilterGroups.Employee",
        filters: [
            { lc: "APP._Filters.Employee", filter: Filters.EMPLOYEE },
        ],
    },
    {
        lc: "APP._FilterGroups.Equipment",
        filters: [
            { lc: "APP._Filters.Machine", filter: Filters.MACHINE },
            { lc: "APP._Filters.MachineType", filter: Filters.MACHINE_TYPE },
            { lc: "APP._Filters.MachineComponent", filter: Filters.MACHINE_COMPONENT }
        ],
    },
    {
        lc: "APP._FilterGroups.Material",
        filters: [
            { lc: "APP._Filters.Material", filter: Filters.MATERIAL },
            { lc: "APP._Filters.MaterialType", filter: Filters.MATERIAL_TYPE },
        ],
    },
    {
        lc: "APP._FilterGroups.Resource",
        filters: [
            { lc: "APP._Filters.ResourceRoot", filter: Filters.RESOURCE_ROOT },
            { lc: "APP._Filters.Resource", filter: Filters.RESOURCE },
            { lc: "APP._Filters.ResourceType", filter: Filters.RESOURCE_TYPE },
            { lc: "APP._Filters.RegulationResource", filter: Filters.REGULATION_RESOURCE },
            { lc: "APP._Filters.RegulationResourceTopgroup", filter: Filters.REGULATION_RESOURCE_TOPGROUP },
            { lc: "APP._Filters.RegulationResourceGroup", filter: Filters.REGULATION_RESOURCE_GROUP },
            { lc: "APP._Filters.RegulationResourceSubgroup", filter: Filters.REGULATION_RESOURCE_SUBGROUP },
            { lc: "APP._Filters.RegulationResourceDefaultSource", filter: Filters.REGULATION_RESOURCE_DEFAULT_SOURCE },
            { lc: "APP._Filters.ResourceBenchmarkType", filter: Filters.RESOURCE_BENCHMARK_TYPE },
            { lc: "APP._Filters.ResourceBenchmarkTypeTopgroup", filter: Filters.RESOURCE_BENCHMARK_TYPE_TOPGROUP},
            { lc: "APP._Filters.ResourceBenchmarkTypeGroup", filter: Filters.RESOURCE_BENCHMARK_TYPE_GROUP },
            { lc: "APP._Filters.ResourceBenchmarkTypeSubgroup", filter: Filters.RESOURCE_BENCHMARK_TYPE_SUBGROUP },
            { lc: "APP._Filters.SubmissionResource", filter: Filters.SUBMISSION_RESOURCE },
            { lc: "APP._Filters.SubmissionResourceTopgroup", filter: Filters.SUBMISSION_RESOURCE_TOPGROUP },
            { lc: "APP._Filters.SubmissionResourceGroup", filter: Filters.SUBMISSION_RESOURCE_GROUP },
            { lc: "APP._Filters.SubmissionResourceSubgroup", filter: Filters.SUBMISSION_RESOURCE_SUBGROUP },
            { lc: "APP._Filters.IsDirect", filter: Filters.IS_DIRECT },
        ],
    },
    {
        lc: "APP._FilterGroups.ResourceAfterTransfer",
        filters: [
            { lc: "APP._Filters.ResourceRootAfterTransfer", filter: Filters.RESOURCE_ROOT_AFTER_TRANSFER },
            { lc: "APP._Filters.ResourceAfterTransfer", filter: Filters.RESOURCE_AFTER_TRANSFER },
            { lc: "APP._Filters.ResourceTypeAfterTransfer", filter: Filters.RESOURCE_TYPE_AFTER_TRANSFER },
            { lc: "APP._Filters.RegulationResourceAfterTransfer", filter: Filters.REGULATION_RESOURCE_AFTER_TRANSFER },
            { lc: "APP._Filters.RegulationResourceTopgroupAfterTransfer", filter: Filters.REGULATION_RESOURCE_TOPGROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.RegulationResourceGroupAfterTransfer", filter: Filters.REGULATION_RESOURCE_GROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.RegulationResourceSubgroupAfterTransfer", filter: Filters.REGULATION_RESOURCE_SUBGROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.RegulationResourceDefaultSourceAfterTransfer", filter: Filters.REGULATION_RESOURCE_DEFAULT_SOURCE_AFTER_TRANSFER },
            { lc: "APP._Filters.ResourceBenchmarkTypeAfterTransfer", filter: Filters.RESOURCE_BENCHMARK_TYPE_AFTER_TRANSFER },
            { lc: "APP._Filters.ResourceBenchmarkTypeGroupAfterTransfer", filter: Filters.RESOURCE_BENCHMARK_TYPE_GROUP_AFTER_TRANSFER },
            { lc: "APP._Filters.ResourceBenchmarkTypeSubgroupAfterTransfer", filter: Filters.RESOURCE_BENCHMARK_TYPE_SUBGROUP_AFTER_TRANSFER },
        ],
    },
    {
        lc: "APP._FilterGroups.Task",
        filters: [
            { lc: "APP._Filters.Task", filter: Filters.TASK },
            { lc: "APP._Filters.Subtask", filter: Filters.SUBTASK },
            { lc: "APP._Filters.RegulationTask", filter: Filters.REGULATION_TASK },
            { lc: "APP._Filters.RegulationTaskSubgroup", filter: Filters.REGULATION_TASK_SUBGROUP },
            { lc: "APP._Filters.SubmissionTask", filter: Filters.SUBMISSION_TASK },
            { lc: "APP._Filters.SubmissionTaskTopgroup", filter: Filters.SUBMISSION_TASK_TOPGROUP },
            { lc: "APP._Filters.SubmissionTaskGroup", filter: Filters.SUBMISSION_TASK_GROUP },
            { lc: "APP._Filters.SubmissionTaskSubgroup", filter: Filters.SUBMISSION_TASK_SUBGROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.TaskAfterTransfer",
        filters: [
            { lc: "APP._Filters.TaskAfterTransfer", filter: Filters.TASK_AFTER_TRANSFER },
            { lc: "APP._Filters.RegulationTaskAfterTransfer", filter: Filters.REGULATION_TASK_AFTER_TRANSFER },
        ],
    },
    {
        lc: "APP._FilterGroups.SpecificationAndAllocation",
        filters: [
            { lc: "APP._Filters.RuleType", filter: Filters.RULE_TYPE },
            { lc: "APP._Filters.RuleGroup", filter: Filters.RULE_GROUP },
            { lc: "APP._Filters.Rule", filter: Filters.RULE },
            { lc: "APP._Filters.AdjustmentType", filter: Filters.ADJUSTMENT_TYPE },
            { lc: "APP._Filters.TransferType", filter: Filters.TRANSFER_TYPE },
            { lc: "APP._Filters.TransferMethod", filter: Filters.TRANSFER_METHOD },
            { lc: "APP._Filters.AccountAllocationRuleType", filter: Filters.ACCOUNT_ALLOCATION_RULE_TYPE_ID },
            { lc: "APP._Filters.AccountAllocationRuleGroup", filter: Filters.ACCOUNT_ALLOCATION_RULE_GROUP_ID },
            { lc: "APP._Filters.AccountAllocationRule", filter: Filters.ACCOUNT_ALLOCATION_RULE_ID },
            { lc: "APP._Filters.AccountAllocationCostDriver", filter: Filters.ACCOUNT_ALLOCATION_COST_DRIVER },
            { lc: "APP._Filters.AccountAllocationTransfer", filter: Filters.ACCOUNT_ALLOCATION_TRANSFER_TYPE },
            { lc: "APP._Filters.ResourceAllocationRuleType", filter: Filters.RESOURCE_ALLOCATION_RULE_TYPE },
            { lc: "APP._Filters.ResourceAllocationRuleGroup", filter: Filters.RESOURCE_ALLOCATION_RULE_GROUP },
            { lc: "APP._Filters.ResourceAllocationRule", filter: Filters.RESOURCE_ALLOCATION_RULE },
            { lc: "APP._Filters.SupportCostAllocationRuleType", filter: Filters.SUPPORT_COST_ALLOCATION_RULE_TYPE },
            { lc: "APP._Filters.SupportCostAllocationRuleGroup", filter: Filters.SUPPORT_COST_ALLOCATION_RULE_GROUP },
            { lc: "APP._Filters.SupportCostAllocationRule", filter: Filters.SUPPORT_COST_ALLOCATION_RULE },
            { lc: "APP._Filters.CostDriver", filter: Filters.COST_DRIVER },
            { lc: "APP._Filters.CostDriver", filter: Filters.TRANSFER_COST_DRIVER },
            { lc: "APP._Filters.ExclusionGroup", filter: Filters.EXCLUSION_GROUP },
        ],
    },
    {
        lc: "APP._FilterGroups.Error",
        filters: [
            { lc: "APP._Filters.Errors", filter: Filters.DATA_ERRORS },
            { lc: "APP._Filters.NonZeroOnly", filter: Filters.NON_ZERO_ONLY },
            { lc: "APP._Filters.OnlyErrors", filter: Filters.ONLY_ERRORS },
        ],
    },
    {
        lc: "APP._FilterGroups.Reference",
        filters: [
            { lc: "APP._Filters.ReferenceSource", filter: Filters.REFERENCE_SOURCE },
        ],
    },
];


// ----------------------------------------------------------------------------------
// Filter fields configuration

interface DefinitionMapping {
    [key: string]: keyof IAppDefinitions;
}

interface FilterFields {
    [key: string]: DefinitionMapping;
}

interface FilterMapping {
    [key: string]: {
        [key: string]: Filters;
    };
}


export const FILTER_FIELDS: FilterFields = {
    costCentre: {
        "costCentreTopgroupCode": "costCentreTopgroup",
        "costCentreGroupCode": "costCentreGroup",
        "costCentreSubgroupCode": "costCentreSubgroup",
        "costCentreBenchmarkTypeId": "costCentreBenchmarkType",
        "costCentreRegulationTypeCode": "costCentreRegulationType",
        "costCentreRegulationTypeSubgroupCode": "costCentreRegulationTypeSubgroup",
        "costCentreLevel1Code": "costCentreLevel1",
        "costCentreLevel2Code": "costCentreLevel2",
        "costCentreLevel3Code": "costCentreLevel3",
        "costCentreLevel4Code": "costCentreLevel4",
        "costCentreLevel5Code": "costCentreLevel5",
        "costCentreTypeId": "costCentreType",
    },
    account: {
        "accountGroupCode": "accountGroup",
        "accountSubgroupCode": "accountSubgroup",
        "accountTypeId": "accountType",
        "accountBenchmarkTypeId": "accountBenchmarkType",
        "financialStatementGroupCode": "financialStatementGroup",
        "regulationTypeCode": "accountRegulationType",
        "regulationTypeSubgroupCode": "accountRegulationTypeSubgroup",
    },
    productionUnit: {
        "productionUnitCostCentreCode": "costCentre",
        "productionUnitTopgroupCode": "costCentreTopgroup",
        "productionUnitGroupCode": "costCentreGroup",
        "productionUnitSubgroupCode": "costCentreSubgroup",
        "productionUnitTypeId": "productionUnitType",
        "productionUnitBenchmarkTypeId": "productionUnitBenchmarkType",
        "productionUnitRegulationTypeCode": "costCentreRegulationType",
        "productionUnitRegulationTypeSubgroupCode": "costCentreRegulationTypeSubgroup",
        "productionUnitLevel1Code": "costCentreLevel1",
        "productionUnitLevel2Code": "costCentreLevel2",
        "productionUnitLevel3Code": "costCentreLevel3",
        "productionUnitLevel4Code": "costCentreLevel4",
        "productionUnitLevel5Code": "costCentreLevel5",
    },
    jobTitle: {
        "jobTitleGroupCode": "jobTitleGroup",
        "jobTitleTypeId": "jobTitleType",
        "jobTitleBenchmarkTypeId": "jobTitleBenchmarkType",
    },
    activity: {
        "activityTopgroupCode": "activityTopgroup",
        "activityGroupCode": "activityGroup",
        "activitySubgroupCode": "activitySubgroup",
        "activityTypeId": "activityType"
    },
    product: {
        "productTopgroupCode": "productTopgroup",
        "productGroupCode": "productGroup",
        "productTypeCode": "productType"
    },
    resource: {
        "resourceTypeCode": "resourceType",
    },
    regulationResource: {
        "regulationResourceTopgroupCode": "regulationResourceTopgroup",
        "regulationResourceGroupCode": "regulationResourceGroup",
        "regulationResourceSubgroupCode": "regulationResourceSubgroup",
    },
    regulationTask: {
        "regulationTaskSubgroupCode": "regulationTaskSubgroup",
    },
    material: {
        "materialTypeId": "materialType"
    },
    machine: {
        "machineTypeId": "machineType"
    }
};


export const FILTER_MAPPING: FilterMapping = {
    costCentre: {
        "costCentreCode": Filters.COST_CENTRE,
        "costCentreTopgroupCode": Filters.COST_CENTRE_TOPGROUP,
        "costCentreGroupCode": Filters.COST_CENTRE_GROUP,
        "costCentreSubgroupCode": Filters.COST_CENTRE_SUBGROUP,
        "costCentreTypeId": Filters.COST_CENTRE_TYPE,
        "costCentreBenchmarkTypeId": Filters.COST_CENTRE_BENCHMARK_TYPE,
        "costCentreRegulationTypeCode": Filters.COST_CENTRE_REGULATION_TYPE,
        "costCentreRegulationTypeSubgroupCode": Filters.COST_CENTRE_REGULATION_TYPE_SUBGROUP,
        "costCentreLevel1Code": Filters.COST_CENTRE_LEVEL1,
        "costCentreLevel2Code": Filters.COST_CENTRE_LEVEL2,
        "costCentreLevel3Code": Filters.COST_CENTRE_LEVEL3,
        "costCentreLevel4Code": Filters.COST_CENTRE_LEVEL4,
        "costCentreLevel5Code": Filters.COST_CENTRE_LEVEL5,
    },
    account: {
        "accountCode": Filters.ACCOUNT,
        "accountGroupCode": Filters.ACCOUNT_GROUP,
        "accountSubgroupCode": Filters.ACCOUNT_SUBGROUP,
        "accountTypeId": Filters.ACCOUNT_TYPE,
        "accountBenchmarkTypeId": Filters.ACCOUNT_BENCHMARK_TYPE,
        "financialStatementGroupCode": Filters.FINANCIAL_STATEMENT_GROUP,
        "regulationTypeCode": Filters.ACCOUNT_REGULATION_TYPE,
        "regulationTypeSubgroupCode": Filters.ACCOUNT_REGULATION_TYPE_SUBGROUP
    },
    productionUnit: {
        "productionUnitCode": Filters.PRODUCTION_UNIT,
        "productionUnitCostCentreCode": Filters.PRODUCTION_UNIT_COST_CENTRE,
        "productionUnitTopgroupCode": Filters.PRODUCTION_UNIT_TOPGROUP,
        "productionUnitGroupCode": Filters.PRODUCTION_UNIT_GROUP,
        "productionUnitSubgroupCode": Filters.PRODUCTION_UNIT_SUBGROUP,
        "productionUnitTypeId": Filters.PRODUCTION_UNIT_TYPE,
        "productionUnitBenchmarkTypeId": Filters.PRODUCTION_UNIT_BENCHMARK_TYPE,
        "productionUnitRegulationTypeCode": Filters.PRODUCTION_UNIT_REGULATION_TYPE,
        "productionUnitRegulationTypeSubgroupCode": Filters.PRODUCTION_UNIT_REGULATION_TYPE_SUBGROUP,
        "productionUnitLevel1Code": Filters.PRODUCTION_UNIT_LEVEL1,
        "productionUnitLevel2Code": Filters.PRODUCTION_UNIT_LEVEL2,
        "productionUnitLevel3Code": Filters.PRODUCTION_UNIT_LEVEL3,
        "productionUnitLevel4Code": Filters.PRODUCTION_UNIT_LEVEL4,
        "productionUnitLevel5Code": Filters.PRODUCTION_UNIT_LEVEL5,
    },
    jobTitle: {
        "jobTitleGroupCode": Filters.JOB_TITLE_GROUP,
        "jobTitleCode": Filters.JOB_TITLE,
        "jobTitleTypeId": Filters.JOB_TITLE_TYPE,
        "jobTitleBenchmarkTypeId": Filters.JOB_TITLE_BENCHMARK_TYPE,
    },
    activity: {
        "activityTopgroupCode": Filters.ACTIVITY_TOPGROUP,
        "activityGroupCode": Filters.ACTIVITY_GROUP,
        "activitySubgroupCode": Filters.ACTIVITY_SUBGROUP,
        "activityTypeId": Filters.ACTIVITY_TYPE,
        "activityCode": Filters.ACTIVITY,
    },
    product: {
        "productTopgroupCode": Filters.PRODUCT_TOPGROUP,
        "productGroupCode": Filters.PRODUCT_GROUP,
        "productTypeCode": Filters.PRODUCT_TYPE,
        "productCode": Filters.PRODUCT,
    },
    resource: {
        "resourceRootCode": Filters.RESOURCE_ROOT,
        "resourceCode": Filters.RESOURCE,
        "resourceTypeCode": Filters.RESOURCE_TYPE
    },
    regulationResource: {
        "regulationResourceCode": Filters.REGULATION_RESOURCE,
        "regulationResourceTopgroupCode": Filters.REGULATION_RESOURCE_TOPGROUP,
        "regulationResourceGroupCode": Filters.REGULATION_RESOURCE_GROUP,
        "regulationResourceSubgroupCode": Filters.REGULATION_RESOURCE_SUBGROUP,
    },
    regulationTask: {
        "regulationTaskCode": Filters.REGULATION_TASK,
        "regulationTaskSubgroupCode": Filters.REGULATION_TASK_SUBGROUP,
    },
    material: {
        "materialTypeId": Filters.MATERIAL_TYPE
    },
    machine: {
        "machineTypeId": Filters.MACHINE_TYPE
    }
};
