import { inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UserSettingsService } from "@logex/framework/lg-application";
import { RULES_DIALOG_TYPE_SETTING_KEY } from "@shared/types/misc";
import { Tabs as EditRulesDialogTabs } from "@shared/dialogs/edit-rules-dialog/types/constants";
import { RuleSection } from "@shared/dialogs/rules-dialog/types/constants";
import {
    EditRulesDialog,
    EditRulesDialogDialogResult
} from "@shared/dialogs/edit-rules-dialog/edit-rules-dialog.component";
import { RulesDialog, RulesDialogDialogResult } from "@shared/dialogs/rules-dialog/rules-dialog.component";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LoadManager } from "@logex/load-manager";
import { ReloadTriggerService } from "@shared/services/reload-trigger.service";


type TDialogType = "new" | "old";


@Injectable({
    providedIn: "root"
})
export class RulesDialogSwitcherService {
    protected _userSettingsService = inject(UserSettingsService);
    protected _oldRulesDialog = inject(EditRulesDialog);
    protected _rulesDialog = inject(RulesDialog);
    protected _lgTranslate = inject(LgTranslateService);
    protected _promptDialog = inject(LgPromptDialog);
    protected _loadManager = inject(LoadManager);
    protected _reloadTriggerService = inject(ReloadTriggerService);

    async setPreferredRuleDialog(type: string): Promise<void> {
        await firstValueFrom(this._userSettingsService.set({
            key: RULES_DIALOG_TYPE_SETTING_KEY,
            value: { type },
        }));
    }

    async showRulesDialogChallenge(): Promise<string> {
        return this._promptDialog.confirm(
            this._lgTranslate.translate("APP._RulesDialogSelector.Title"),
            this._lgTranslate.translate("APP._RulesDialogSelector.Body"),
            {
                buttons: [
                    {
                        id: "new",
                        name: this._lgTranslate.translate("APP._RulesDialogSelector.New"),
                        isConfirmAction: true
                    }, {
                        id: "old",
                        name: this._lgTranslate.translate("APP._RulesDialogSelector.Old"),
                        isCancelAction: true
                    }
                ]
            }
        );
    }

    async getPreferredRuleDialogType(): Promise<TDialogType> {
        const uiSettings = await firstValueFrom(
            this._userSettingsService.get(RULES_DIALOG_TYPE_SETTING_KEY)
        );

        if (uiSettings[0]?.value?.type != null) {
            return uiSettings[0]?.value?.type;
        }

        const typeSelection = await this.showRulesDialogChallenge() as TDialogType;
        await this.setPreferredRuleDialog(typeSelection);
        return typeSelection;
    }

    async openPreferredRuleDialog(section: RuleSection): Promise<void> {

        let convertedTab: EditRulesDialogTabs;
        switch (section) {
            case RuleSection.SubaccountSpecification:
                convertedTab = EditRulesDialogTabs.AccountSplitRules;
                break;
            case RuleSection.AccountTransfer:
                convertedTab = EditRulesDialogTabs.AccountTransferRules;
                break;
            case RuleSection.ResourceSpecification:
                convertedTab = EditRulesDialogTabs.AccountToResourceRules;
                break;
            case RuleSection.ResourceTransfer:
                convertedTab = EditRulesDialogTabs.ResourceTransferRules;
                break;
            case RuleSection.TaskSpecification:
                convertedTab = EditRulesDialogTabs.ResourceToTaskRules;
                break;
            case RuleSection.SupportCostTransfer:
                convertedTab = EditRulesDialogTabs.ResourceTaskTransferRules;
                break;
            case RuleSection.SubtaskSpecification:
                convertedTab = EditRulesDialogTabs.ResourceTaskSplitRules;
                break;
            case RuleSection.MapToProduction:
                convertedTab = EditRulesDialogTabs.ResourceTaskToActivityRules;
                break;
        }

        let dialog: RulesDialogDialogResult | EditRulesDialogDialogResult;
        if (await this.getPreferredRuleDialogType() === "new") {
            dialog = await this._rulesDialog.show({ section });
        } else {
            dialog = await this._oldRulesDialog.show({ tab: convertedTab });
        }

        if (dialog.reload) {
            this._reloadTriggerService.trigger.next();
        }
    }
}
