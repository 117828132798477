import * as _ from "lodash";
import { Component, inject, Input, ViewEncapsulation } from "@angular/core";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { DefinitionsCostDriver, IAppDefinitions } from "@shared/app-definitions.types";
import { ResourceManagementDialog } from "@shared/dialogs/resource-management-dialog/resource-management-dialog.component";
import { SOURCE_RULE_FILTER_SELECTOR } from "../bases/transfer-rules-base/types";

import { EditAccountToResourceRulesGateway } from "./gateways/edit-account-to-resource-rules-gateway";
import { CostDriverInfoExt, RuleExt } from "./gateways/edit-account-to-resource-rules-gateway.types";
import { RulesPivotLevel2Ext } from "./pivots/transfer-rules-pivot.types";
import { RuleGroup } from "../bases/rules-base/gateways/rules-gateway.types";
import { RulesComponentBase } from "../bases/rules-base/rules-component-base";


@Component({
    selector: "mod-edit-account-to-resource-rules",
    templateUrl: "./edit-account-to-resource-rules.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog.AccountToResourceRules"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
    ],
})
export class EditAccountToResourceRulesComponent extends RulesComponentBase<RulesPivotLevel2Ext, CostDriverInfoExt> {

    private _resourceManagementDialog = inject(ResourceManagementDialog);
    _gateway = inject(EditAccountToResourceRulesGateway);

    // ----------------------------------------------------------------------------------
    // Fields

    @Input() isReadonly = false;

    _costDriverDropdown: IDropdownDefinition<number>;
    private _defaultCostDriver: DefinitionsCostDriver;

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["resourceType", "subAccountSourceType"];


    // ----------------------------------------------------------------------------------
    // Methods

    protected _processLoadedData(
        rules: RuleExt[],
        groups: RuleGroup[],
        data: CostDriverInfoExt[]
    ): void {
        super._processLoadedData(rules, groups, data);

        const costDrivers = _.uniq(_.map(data, x => x.costDriverId));
        const orderedCostDrivers = _.sortBy(
            _.map(costDrivers, x => this._definitions.costDriver[x]),
            "orderBy"
        );
        this._costDriverDropdown = this._defineCostDriverDropdown(orderedCostDrivers);
        this._defaultCostDriver = _.first(orderedCostDrivers);
    }


    protected _processLoadedCostDriverInfo(costDrivers: CostDriverInfoExt[]): void {
        this._costDriversInfoLookup = _.keyBy(costDrivers, "costDriverId");
    }


    protected _newRule(groupId: number, position: number) {
        const row = {
            groupId,
            position,
            isEnabled: true,
            name: "",
        } as RulesPivotLevel2Ext;

        this._onCostDriverSelected(row, this._defaultCostDriver.id);

        return row;
    }


    protected _updateRuleAfterChanges(row: RulesPivotLevel2Ext, changedFields: Readonly<Partial<RulesPivotLevel2Ext>>): void {
        if (changedFields.costDriverId !== undefined) {
            const costDriverInfo = this._getCostDriverInfo(row);
            row.allowedUids = costDriverInfo.uids;

            // Clear all target fields that are not allowed in this cost driver
            _.forOwn(row.filters, (value, field) => {
                if (!row.allowedUids?.includes(field)) {
                    delete row.filters[field];
                }
            });

            if (row?.filters && Object.keys(row?.filters)?.length === 0) {
                row.filters = undefined;
            }

            // Update resource type dropdown
            let resourceTypeDropdownEntries: Array<{ id: string; name: string; }>;
            if (costDriverInfo?.resourceTypeCodes != null) {
                resourceTypeDropdownEntries = _.map(costDriverInfo?.resourceTypeCodes, id => ({
                    id,
                    name: this._definitions.getDisplayName("resourceType", id),
                }));
            } else {
                resourceTypeDropdownEntries = [{ id: null, name: "—" }];
            }

            row.resourceTypeDropdown = dropdownFlat({
                entryId: "id",
                entryName: "name",
                entries: resourceTypeDropdownEntries,
            });

            if (!_.some(costDriverInfo?.resourceTypeCodes, x => x === row.resourceTypeCode)) {
                this._onResourceTypeSelected(row,
                    costDriverInfo?.resourceTypeCodes != null ? _.first(costDriverInfo?.resourceTypeCodes) : null);
            }
        }
    }


    _isSourceAllowed(row: RulesPivotLevel2Ext, field: string): boolean {
        return _.includes(row.allowedUids, field);
    }


    _onResourceTypeSelected(row: RulesPivotLevel2Ext, value: string): void {
        if (row.resourceTypeCode === value) return;

        row.resourceTypeCode = value;

        this._updateRuleAfterChanges(row, { resourceTypeCode: row.resourceTypeCode });
        this._markModified(row);
    }


    protected _areEqualRules(row: RulesPivotLevel2Ext, originalRow: RuleExt): boolean {
        return super._areEqualRules(row, originalRow)
            && row.resourceTypeCode === originalRow.resourceTypeCode
            && _.isEqual(row.filters, originalRow.filters);
    }


    _openResourceManager(): void {
        this._resourceManagementDialog
            .show({
                isReadonly: this.isReadonly,
            });
    }
}
