import * as _ from "lodash";
import { inject } from "@angular/core";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { firstValueFrom, forkJoin } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { LG_APP_SESSION, LG_FEATURES } from "@logex/framework/lg-application";
import { IDropdownDefinition, LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgPivotInstance, LgPivotInstanceFactory, LogexPivotService } from "@logex/framework/lg-pivot";
import { HandleErrorsMixin } from "@logex/mixins";

import { AppDefinitions } from "@shared/app-definitions.service";
import { DefinitionsCostDriver, IAppDefinitions } from "@shared/app-definitions.types";
import { AppSession } from "@shared/types/app-session";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { HelpTooltip } from "@shared";
import { loadTargetFilters } from "@shared/services/rule-filter-selector/utils/loadTargetFilters";

import { RulesPivotLevel1, RulesPivotLevel2, RulesPivotTotals } from "./pivots/rules-pivot.types";
import { CostDriverInfo, Rule, RuleGroup, SelectFiltersSchema } from "./gateways/rules-gateway.types";
import { RulesPivot } from "./pivots/rules-pivot.service";
import { RulesGateway } from "./gateways/rules-gateway";
import { TransferRulesPivotLevel2 } from "../transfer-rules-base/types";


// ----------------------------------------------------------------------------------
export interface RulesDataChanges<TRulesPivotLevel2 extends RulesPivotLevel2<Rule>> {
    groupsUpdated: Array<RulesPivotLevel1<TRulesPivotLevel2>>;
    groupsDeleted: number[];
    rulesUpdated: TRulesPivotLevel2[];
    rulesDeleted: number[];
}

// Used in drag and drop as a type of the entry of flat ordered list of groups and rules - as they are seen.
type OrderedRulesNode<TRulesPivotLevel2 extends RulesPivotLevel2<Rule>> = [
    node: RulesPivotLevel1<TRulesPivotLevel2> | TRulesPivotLevel2,
    isGroup: boolean,
    position: number
];


// ----------------------------------------------------------------------------------
export interface RulesComponentBase<TRulesPivotLevel2, TDriver>
    extends HandleErrorsMixin {
}

@mixins(HandleErrorsMixin)
export abstract class RulesComponentBase<TRulesPivotLevel2 extends RulesPivotLevel2<Rule> = RulesPivotLevel2<Rule>,
    TDriver extends CostDriverInfo = CostDriverInfo> {

    protected _features = inject(LG_FEATURES);
    protected _session = inject<AppSession>(LG_APP_SESSION);
    protected _definitions = inject(AppDefinitions);
    public _promptDialog = inject(LgPromptDialog);
    public _lgTranslate = inject(LgTranslateService);
    protected _lgConsole = inject(LgConsole);
    public _ruleFilterSelector = inject(RuleFilterSelectorService);
    protected _pivotService = inject(LogexPivotService);

    protected abstract _gateway: RulesGateway<Rule, TDriver>;

    constructor() {
        const pivotInstanceFactory = inject(LgPivotInstanceFactory);
        const rulesPivot = inject(RulesPivot);

        this._rulesPivot = pivotInstanceFactory.create<RulesPivotLevel1<TRulesPivotLevel2>, RulesPivotTotals>(rulesPivot, this);

        this._ruleFilterSelector.configure({
            selectOptionsCallback: (uid, row) => this._gateway.selectFilterData({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                uid,
            })
        });
    }


    // ----------------------------------------------------------------------------------
    // Fields
    public isActivated = false;
    protected _isLoading = false;
    protected _isModified = false;

    protected _originalRules: Rule[];
    protected _originalRuleGroups: RuleGroup[];
    _ruleGroups: RuleGroup[]; // Copy of original, possibly extended with default group
    _rulesPivot: LgPivotInstance<RulesPivotLevel1<TRulesPivotLevel2>, RulesPivotTotals>;

    protected _costDriversInfoLookup: _.Dictionary<TDriver>;

    public _sourceSelectorConfig: RuleFilterInfo[];

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = [];

    _helpTooltip = HelpTooltip;


    // ----------------------------------------------------------------------------------
    async activate(): Promise<void> {
        if (this.isActivated) return;

        await firstValueFrom(this._definitions.load(...this._requiredDefinitions));
        await this._load();

        this.isActivated = true;
    }


    private async _load(): Promise<void> {
        this._isLoading = true;

        try {
            const args = {
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId
            };
            const [rules, groups, costDrivers, filtersSchema] = await firstValueFrom(forkJoin([
                this._gateway.selectRules(args),
                this._gateway.selectRuleGroups(args),
                this._gateway.selectCostDrivers(args),
                this._gateway.selectFiltersSchema(args),
            ]));

            await this._processFiltersSchema(filtersSchema);

            this._processLoadedData(rules, groups, costDrivers);

        } catch (e: any) {
            this._onException(e);
            throw e;

        } finally {
            this._isLoading = false;
        }
    }


    get isLoading(): boolean {
        return this._isLoading;
    }


    protected async _processFiltersSchema(filtersSchema: SelectFiltersSchema): Promise<void> {
        this._sourceSelectorConfig = await loadTargetFilters(filtersSchema.filters, this._definitions, this._lgTranslate);
    }


    protected _processLoadedData(
        rules: Rule[],
        groups: RuleGroup[],
        costDrivers: CostDriverInfo[]
    ): void {
        this._updateOriginalValues(rules, groups);

        this._ruleGroups = this._ensureNonStandardGroupExists(groups);

        this._processLoadedCostDriverInfo(costDrivers);

        this._rulesPivot.build(rules);

        // Check if there is any group without rules. We will need to add it manually to the pivot
        const groupsSet = new Set<number>();

        // Count the number of non-standard rules
        let nonStandardRulesCounter = 0;

        for (const group of groups) {
            groupsSet.add(group.id);

            if (!group.isStandard) {
                nonStandardRulesCounter++;
            }
        }

        _.each(this._rulesPivot.all, row1 => {
            // If there is only one non-standard rule, show it expanded
            if (!row1.isStandard && nonStandardRulesCounter === 1) {
                row1.$expanded = true;
            }

            groupsSet.delete(row1.id);

            _.each(row1.children, row2 => {
                this._updateRuleAfterChanges(row2, row2);
            });
        });

        // Add empty groups if there are any
        if (groupsSet.size > 0) {
            groupsSet.forEach(groupId => {
                const group = groups.find(x => x.id === groupId);
                this._rulesPivot.all.push({
                    id: group.id,
                    name: group.name,
                    position: group.position,
                    isEnabled: group.isEnabled,
                    isStandard: group.isStandard,
                    $expanded: !group.isStandard,
                    children: [],
                    filteredChildren: []
                });
            });
            this._rulesPivot.refilter();
            this._rulesPivot.sort();
        }

        this._isModified = false;
    }


    private _gatherRowsFromPivot(): {
        rules: Rule[];
        groups: RuleGroup[];
    } {
        const groups: RuleGroup[] = [];
        const rules: Rule[] = [];

        this._pivotService.eachNodeAtLevel(
            this._rulesPivot.definition,
            this._rulesPivot.filtered,
            0,
            (row: RulesPivotLevel1<TRulesPivotLevel2>) => groups.push({
                id: row.id,
                name: row.name,
                position: row.position,
                isEnabled: row.isEnabled,
                isStandard: row.isStandard,
            })
        );

        this._pivotService.eachNodeAtLevel(
            this._rulesPivot.definition,
            this._rulesPivot.filtered,
            1,
            (row: TRulesPivotLevel2) => rules.push(row)
        );

        return { rules, groups };
    }


    private _updateOriginalValues(
        rules: Rule[],
        groups: RuleGroup[],
    ): void {
        this._originalRules = _.cloneDeep(rules);
        this._originalRuleGroups = _.cloneDeep(groups);
    }


    private _ensureNonStandardGroupExists(groups: RuleGroup[]): RuleGroup[] {
        const nonStandardGroups = _.filter(groups, x => !x.isStandard);
        if (!_.isEmpty(nonStandardGroups)) return groups;

        return [
            ...groups,
            {
                id: this._getNextId(groups),
                position: this._getNextPosition(groups),
                name: this._lgTranslate.translate(".DefaultRuleGroupName"),
                isEnabled: true,
                isStandard: false,
            }
        ];
    }


    private _getNextId(groups: Array<{ id: number; }>): number {
        const maxId = _.max(_.map(groups, x => x.id)) ?? 0;
        return (maxId < 0 ? 0 : maxId) + 1;
    }


    private _getNextPosition(groups: Array<{ position: number; }>): number {
        const maxPosition = _.max(_.map(groups, x => x.position)) ?? 0;
        return (maxPosition < 0 ? 0 : maxPosition) + 1;
    }


    protected abstract _processLoadedCostDriverInfo(costDrivers: CostDriverInfo[]): void;


    protected _defineCostDriverDropdown(costDrivers: DefinitionsCostDriver[]): IDropdownDefinition<number> {
        return dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: _.map(costDrivers, x => x),
        });
    }


    // ----------------------------------------------------------------------------------
    // Editing

    addRuleGroup(): void {
        const newGroup = this._newGroup(
            this._getNextId(this._ruleGroups),
            this._getNextPosition(this._rulesPivot.all)
        );
        this._ruleGroups.push(newGroup);
        this._rulesPivot.all.push(newGroup);
        this._rulesPivot.refilter();
        this._markModified(newGroup);
        this._rulesPivot.ensureVisible(newGroup.id);
    }


    private _newGroup(groupId: number, position: number): RulesPivotLevel1<TRulesPivotLevel2> {
        return {
            id: groupId,
            position,
            name: "",
            isEnabled: true,
            isStandard: false,
            children: [],
            filteredChildren: [],
            $expanded: true,
        };
    }


    addRule(event: MouseEvent, groupId: number): void {
        event.stopPropagation();

        const groupNode = this._rulesPivot.all.find(x => x.id === groupId);
        const position = Math.max(0, ...groupNode.children.map(x => x.position)) + 1;

        const row = this._newRule(groupId, position);
        this._updateRuleAfterChanges(row, row);
        this._markModified(row);

        this._rulesPivot.reattachNodeAndEnsureVisible(row, [groupId, position]);
    }


    addRuleAfter(event: MouseEvent, groupId: number, position: number): void {
        event.stopPropagation();

        const groupNode = this._rulesPivot.all.find(x => x.id === groupId);

        // Increase position of latter rules
        groupNode.children
            .filter(x => x.position > position)
            .forEach(x => {
                this._markModified(x);
                x.position++;
            });

        // Insert new item
        const row = this._newRule(groupId, position + 1);
        this._updateRuleAfterChanges(row, row);
        this._markModified(row);

        groupNode.children.splice(position + 1, 0, row);

        this._rulesPivot.refilter();
    }


    protected abstract _newRule(groupId: number, position: number): TRulesPivotLevel2;


    protected abstract _updateRuleAfterChanges(row: TRulesPivotLevel2, changedFields: Readonly<Partial<TRulesPivotLevel2>>): void;


    protected _getCostDriverInfo(row: TRulesPivotLevel2): TDriver {
        const res = this._costDriversInfoLookup[row.costDriverId];

        if (res == null) {
            this._lgConsole.error(`Cost driver ${row.costDriverId} does not exist`);
        }

        return res;
    }


    // ----------------------------------------------------------------------------------
    _onCostDriverSelected(row: TRulesPivotLevel2, value: number): void {
        if (row.costDriverId === value) return;

        row.costDriverId = value;

        this._updateRuleAfterChanges(row, { costDriverId: row.costDriverId } as Partial<TRulesPivotLevel2>);
        this._markModified(row);
    }


    protected _updatedFilters(filters: _.Dictionary<unknown[]>, name: string, value: unknown[]): _.Dictionary<unknown[]> {
        if (filters == null) filters = {};

        if (_.isEqual(filters[name], value)) return filters;

        if (_.isEmpty(value)) {
            delete filters[name];
        } else {
            filters[name] = value;
        }

        if (Object.keys(filters).length === 0) {
            filters = undefined;
        }

        return filters;
    }


    _markModified(row: { isModified?: boolean; }): void {
        row.isModified = true;
        this._isModified = true;
    }


    async _deleteGroup($event: MouseEvent, row: RulesPivotLevel1<TRulesPivotLevel2>): Promise<void> {
        $event.preventDefault();
        $event.stopPropagation();

        let response = "save";

        if (row.children.length > 0) {
            response = await this._promptDialog.confirm(
                this._lgTranslate.translate("APP._EditRulesDialog.ConfirmGroupRemoval.Title"),
                this._lgTranslate.translate("APP._EditRulesDialog.ConfirmGroupRemoval.Body"),
                {
                    buttons: [
                        {
                            id: "save",
                            name: this._lgTranslate.translate("APP._EditRulesDialog.ConfirmGroupRemoval.Save"),
                            isConfirmAction: true
                        },
                        {
                            id: "cancel",
                            name: this._lgTranslate.translate("APP._EditRulesDialog.ConfirmGroupRemoval.Cancel"),
                            isCancelAction: true
                        }
                    ]
                }
            );
        }

        if (response === "save") {
            _.remove(this._rulesPivot.all, row);
            this._rulesPivot.refilter();
            this._isModified = true;
        }
    }


    _deleteRule(row: TRulesPivotLevel2): void {
        this._rulesPivot.removeLeafNode(row);
        this._rulesPivot.refilter();
        this._isModified = true;
    }


    // ----------------------------------------------------------------------------------
    // Drag and drop

    _dropGroup(event: CdkDragDrop<Rule>): void {
        this._dropNode(event, (event, node, nodes) => {
            const targetPositionInNodes = event.currentIndex - event.previousIndex + node[2];
            const targetNode = nodes[targetPositionInNodes];

            // You can drop only on another group or on the last rule in a group
            if (targetNode[1] // Target is a group
                || targetPositionInNodes === nodes.length - 1) { // Target is the very last node
                return targetPositionInNodes;
            }

            // Otherwise you cannot drop here
            return undefined;
        });
    }


    _dropRule(event: CdkDragDrop<Rule>): void {
        this._dropNode(event, (event, node) => {
            const targetPositionInNodes = event.currentIndex - event.previousIndex + node[2];
            if (targetPositionInNodes === 0) {
                // You cannot put a rule before the first group
                return 1;
            }
            return targetPositionInNodes;
        });
    }


    private _dropNode(
        event: CdkDragDrop<Rule>,
        getTargetPosition: (
            event: CdkDragDrop<Rule>,
            node: OrderedRulesNode<TRulesPivotLevel2>,
            nodes: Array<OrderedRulesNode<TRulesPivotLevel2>>
        ) => number
    ): void {

        // Do not perform drag-n-drop if user visibly drag the element outside of the container
        if (!event.isPointerOverContainer) return;

        // Make a list of all nodes in the correct order
        const nodes = this._makeOrderedRulesArray(this._rulesPivot.filtered);

        // Find node corresponding to the dropped row
        const node = _.find(nodes, x => x[0] === event.item.data);

        if (node == null) {
            throw new Error(`Didn't find dropped element - SHOULDN'T happen`);
        }

        const targetPositionInNodes = getTargetPosition(event, node, nodes);

        // Drop could be cancelled
        if (targetPositionInNodes === undefined) return;

        // Do not drop before any standard rule
        if (nodes[targetPositionInNodes][0].isStandard) return;
        if (nodes[targetPositionInNodes][1]
            && targetPositionInNodes > 0
            && nodes[targetPositionInNodes - 1][0].isStandard
        ) return;

        // Move dragged node in nodes array
        let nodesToMove = 1;

        // If this is a group - move all the content
        if (node[1]) {
            for (let i = node[2] + 1; i < nodes.length && !nodes[i][1]; i++) {
                nodesToMove++;
            }
        }

        nodes.splice(
            targetPositionInNodes > node[2] ? targetPositionInNodes - nodesToMove + 1 : targetPositionInNodes,
            0,
            ...nodes.splice(node[2], nodesToMove)
        );

        // Renumber the nodes from the beginning
        this._repositionOrderedRules(nodes);

        this._rulesPivot.refilter();
    }


    private _makeOrderedRulesArray(level1: Array<RulesPivotLevel1<TRulesPivotLevel2>>): Array<OrderedRulesNode<TRulesPivotLevel2>> {
        const nodes: Array<OrderedRulesNode<TRulesPivotLevel2>> = [];
        let position = 0;
        _.each(level1, row1 => {
            nodes.push([row1, true, position++]);

            // Add child nodes only if parent node is expanded
            if (row1.$expanded) {
                _.each(row1.filteredChildren, row2 => {
                    nodes.push([row2, false, position++]);
                });
            }
        });
        return nodes;
    }


    private _repositionOrderedRules(nodes: Array<OrderedRulesNode<TRulesPivotLevel2>>): void {
        let i = 0;
        let currentGroup: RulesPivotLevel1<TRulesPivotLevel2>;
        let groupPosition = 0;
        let rulePosition;
        _.each(nodes, x => {
            // If group
            if (x[1]) {
                currentGroup = x[0] as RulesPivotLevel1<TRulesPivotLevel2>;

                // Skip standard groups from repositioning
                if (!currentGroup.isStandard) {
                    groupPosition++;

                    if (currentGroup.position !== groupPosition) {
                        currentGroup.position = groupPosition;
                        this._markModified(currentGroup);
                    }

                    // Reset rules counter
                    rulePosition = 0;
                }
            } else {
                const currentRule = x[0] as TRulesPivotLevel2;

                // Skip standard rules from repositioning
                if (!currentRule.isStandard) {
                    rulePosition++;

                    if (currentRule.groupId !== currentGroup.id) {
                        // Move to another group
                        this._rulesPivot.removeLeafNode(currentRule);
                        currentRule.groupId = currentGroup.id;
                        currentRule.position = rulePosition;
                        this._rulesPivot.reattachLeafNode(currentRule);
                        this._markModified(currentRule);

                    } else if (currentRule.position !== rulePosition) {
                        currentRule.position = rulePosition;
                        this._markModified(currentRule);
                    }
                }
            }

            // Update the node index
            x[2] = i++;
        });
    }


    // ----------------------------------------------------------------------------------
    // Save

    public isModified(): boolean {
        return this._isModified;
    }


    protected _isValid(): boolean {
        return _.every(this._rulesPivot.all, row1 => !_.isEmpty(row1.name)
            && _.every(row1.children, row2 => !_.isEmpty(row2.name)
                && row2.costDriverId != null));
    }


    private _getChanges(): RulesDataChanges<TRulesPivotLevel2> {
        const rulesUpdated: TRulesPivotLevel2[] = [];
        const ruleIds = new Set<number>();
        const groupsUpdated: Array<RulesPivotLevel1<TRulesPivotLevel2>> = [];
        const groupIds = new Set<number>();

        // Each group
        _.each(this._rulesPivot.all, row1 => {
            // If group is empty, then consider it deleted
            // if ( _.isEmpty( row1.children ) ) return; // continue

            groupIds.add(row1.id);
            const originalGroup = _.find(this._originalRuleGroups, { id: row1.id });
            if (originalGroup == null
                || !this._areEqualGroups(row1, originalGroup)) {
                groupsUpdated.push(row1);
            }

            // Each rule in the group
            _.each(row1.children, row2 => {
                if (row2.id != null) ruleIds.add(row2.id);

                if (!row2.isModified) return;

                // Check original
                if (row2.id != null) {
                    const originalRow = _.find(this._originalRules, { id: row2.id });
                    if (!this._areEqualRules(row2, originalRow)) {
                        rulesUpdated.push(row2);
                    }
                } else {
                    // If id is null then this is a new row
                    rulesUpdated.push(row2);
                }
            });
        });

        const groupsDeleted = _.filter(_.map(this._originalRuleGroups, x => x.id), id => !groupIds.has(id));
        const rulesDeleted = _.filter(_.map(this._originalRules, x => x.id), id => !ruleIds.has(id));

        return { groupsUpdated, groupsDeleted, rulesUpdated, rulesDeleted };
    }


    protected _areEqualGroups(row1: RulesPivotLevel1<TRulesPivotLevel2>, originalGroup: RuleGroup): boolean {
        return row1.position === originalGroup.position
            && row1.name === originalGroup.name
            && row1.isEnabled === originalGroup.isEnabled;
    }


    protected _areEqualRules(row: TRulesPivotLevel2, originalRow: Rule): boolean {
        if (row.isEnabled !== originalRow.isEnabled) return false;
        if (row.groupId !== originalRow.groupId) return false;
        if (row.position !== originalRow.position) return false;
        if (row.name !== originalRow.name) return false;
        if (row.costDriverId !== originalRow.costDriverId) return false;

        return true;
    }


    protected _areEqualFilters(selectorConfig: RuleFilterInfo[], updated: _.Dictionary<unknown[]>, original: _.Dictionary<unknown[]>): boolean {
        if (original == null && updated == null) return true;
        if (original == null || updated == null) return false;

        return _.every(selectorConfig,
            x => _.isEqual(updated[x.uid], original[x.uid]));
    }


    async _onFilterChange(
        row: TransferRulesPivotLevel2,
        selector: string,
        value: string[]
    ): Promise<void> {
        row.filters = this._updatedFilters(row.filters, selector, value);
        this._markModified(row);
    }


    public async save(): Promise<boolean> {
        if (!this._isValid()) {
            this._promptDialog.alert(
                this._lgTranslate.translate(".InvalidRulesAlert.Title"),
                this._lgTranslate.translate(".InvalidRulesAlert.Description")
            );
            return false;
        }

        const changes = this._getChanges();

        if (_.isEmpty(changes.groupsUpdated) && _.isEmpty(changes.groupsDeleted)
            && _.isEmpty(changes.rulesUpdated) && _.isEmpty(changes.rulesDeleted)) return true;

        try {
            this._isLoading = true;

            const result = await firstValueFrom(this._gateway
                .saveRules({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    groupsUpdated: _.map(changes.groupsUpdated, this._getDtoForGroup.bind(this)),
                    groupsDeleted: changes.groupsDeleted,
                    rulesUpdated: _.map(changes.rulesUpdated, this._getDtoForRule.bind(this)),
                    rulesDeleted: changes.rulesDeleted,
                }));

            // Set IDs of updated (saved) rules
            changes.rulesUpdated.forEach((updatedRule, i) => {
                updatedRule.id = result.updatedIds[i];
            });

            // Update original values after saving
            const { rules, groups } = this._gatherRowsFromPivot();
            this._updateOriginalValues(rules, groups);

        } catch (err: any) {
            this._onException(err);
            return false;
        } finally {
            this._isLoading = false;
        }

        // Clean isModified flag
        this._isModified = false;
        _.each(this._rulesPivot.all, row => {
            row.isModified = false;
        });

        return true;
    }


    protected _getDtoForGroup(row1: RulesPivotLevel1<TRulesPivotLevel2>): RuleGroup {
        return _.omit(row1, "children", "filteredChildren", "$expanded", "isModified") as RuleGroup;
    }


    protected _getDtoForRule(row2: TRulesPivotLevel2): Rule {
        return _.omit(row2, "isModified", "allowedTargetUids") as Rule;
    }
}
