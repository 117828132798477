import * as _ from "lodash";
import { Component, inject, Input, ViewEncapsulation } from "@angular/core";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { IAppDefinitions } from "@shared/app-definitions.types";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";

import { SOURCE_RULE_FILTER_SELECTOR, TARGET_RULE_FILTER_SELECTOR } from "../bases/transfer-rules-base/types";
import { TransferRulesComponentBase } from "../bases/transfer-rules-base/transfer-rules-component-base";
import { RuleGroup } from "../bases/rules-base/gateways/rules-gateway.types";

import { EditResourceTaskRulesGateway } from "./gateways/edit-resource-task-transfer-rules-gateway";
import { TransferCostDriverInfoExt, TransferRuleExt } from "./gateways/edit-resource-task-transfer-rules-gateway.types";
import { TransferRulesPivotLevel2 } from "./pivots/transfer-rules-pivot.types";


@Component({
    selector: "mod-edit-resource-task-transfer-rules",
    templateUrl: "./edit-resource-task-transfer-rules.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog.ResourceTaskTransferRules"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        { provide: TARGET_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
    ],
})
export class EditResourceTaskTransferRulesComponent extends TransferRulesComponentBase<TransferRulesPivotLevel2, TransferCostDriverInfoExt> {

    _gateway = inject(EditResourceTaskRulesGateway);

    // ----------------------------------------------------------------------------------
    // Fields

    @Input() isReadonly = false;

    _costDriversInfo: TransferCostDriverInfoExt[];
    _methodDropdown: IDropdownDefinition<number>;
    protected _defaultMethodId: number;

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["resourceTaskTransferMethod"];


    // ----------------------------------------------------------------------------------
    // Methods

    protected _processLoadedData(
        rules: TransferRuleExt[],
        groups: RuleGroup[],
        costDrivers: TransferCostDriverInfoExt[]
    ): void {
        super._processLoadedData(rules, groups, costDrivers);

        const orderedMethods = _.sortBy(_.uniq(_.map(costDrivers, x => x.methodId)));
        this._methodDropdown = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: _.map(orderedMethods, id => ({
                id,
                name: this._definitions.getDisplayName("resourceTaskTransferMethod", id),
            })),
        });
        this._defaultMethodId = _.first(orderedMethods);
    }


    protected _processLoadedCostDriverInfo(costDrivers: TransferCostDriverInfoExt[]): void {
        this._costDriversInfo = costDrivers;
        this._costDriversInfoLookup = _.keyBy(costDrivers, x => `${x.methodId}__${x.costDriverId}`);
    }


    protected _newRule(groupId: number, position: number) {
        const row = {
            groupId,
            position,
            isEnabled: true,
            name: "",
        } as TransferRulesPivotLevel2;

        this._onMethodSelected(row, this._defaultMethodId);

        return row;
    }


    protected _updateRuleAfterChanges(row: TransferRulesPivotLevel2, changedFields: Readonly<Partial<TransferRulesPivotLevel2>>): void {
        if (changedFields.methodId != null) {
            const costDrivers = _.filter(this._costDriversInfo, x => row.isStandard || x.methodId === row.methodId);

            const orderedCostDrivers = _.sortBy(
                _.map(costDrivers, x => this._definitions.costDriver[x.costDriverId]),
                "orderBy"
            );
            row.costDriverDropdown = this._defineCostDriverDropdown(orderedCostDrivers);

            if (!_.some(costDrivers, x => x.costDriverId === row.costDriverId)) {
                this._onCostDriverSelected(row, _.first(orderedCostDrivers)?.id);
                return;
            }
        }

        super._updateRuleAfterChanges(row, changedFields);
    }


    protected _areEqualRules(row: TransferRulesPivotLevel2, originalRow: TransferRulesPivotLevel2): boolean {
        if (row.methodId !== originalRow.methodId) return false;
        return super._areEqualRules(row, originalRow);
    }


    protected _getCostDriverInfo(row: TransferRulesPivotLevel2): TransferCostDriverInfoExt {
        return this._costDriversInfoLookup[`${row.methodId}__${row.costDriverId}`];
    }


    _onMethodSelected(row: TransferRulesPivotLevel2, value: number): void {
        if (row.methodId === value) return;

        row.methodId = value;

        this._updateRuleAfterChanges(row, { methodId: row.methodId });
        this._markModified(row);
    }
}
