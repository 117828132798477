import { InjectionToken } from "@angular/core";
import { SettingKey } from "@logex/framework/lg-application";

export interface PasteResult<TKey, TRow> {
    values: TRow[];
    updatedValues: TRow[];
    newValues: TRow[];
    deletedKeys: TKey[];
}

export const APP_VERSION_TITLE = new InjectionToken<string>("APP_VERSION_TITLE");

export const RULES_DIALOG_TYPE_SETTING_KEY: SettingKey = { storageId: "rulesDialogType" };
