<div [lgLoaderOverlay]="_isLoading()"><div class="form-group">
            <div class="tab-multiple-rows">
                <div class="tab-wrapper">
                    <span class="tab-item" *ngFor="let x of _tabsConfig" (click)="_setPageTab( x.page )" [class.tab-item--active]="_currentPageTab === x.page" style="display: flex; align-items: center">
                        {{ x.nameLc | lgTranslate }}
                        <lg-help-tooltip *ngIf="x.help != null" [code]="x.help" style="margin-left: 4px"></lg-help-tooltip>
                    </span>
                </div>
        
                <div class="tab-wrapper">
                    <ng-container *ngFor="let x of _tabsConfig">
                        <ng-container *ngFor="let xx of x.tabs">
                            <span class="tab-item" *ngIf="_currentPageTab === x.page && ( xx.ngIf == null || xx.ngIf )" (click)="_setRuleTab( xx.tab )" [class.tab-item--active]="_currentRuleTab === xx.tab" style="display: flex; align-items: center">
                                {{ xx.nameLc | lgTranslate }}
                                <lg-help-tooltip *ngIf="xx.help != null" [code]="xx.help" style="margin-left: 4px"></lg-help-tooltip>
                            </span>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        <mod-edit-account-split-rules [hidden]="_currentRuleTab != 0" [isReadonly]="_isReadonly"></mod-edit-account-split-rules>

                        <mod-edit-account-transfer-rules [hidden]="_currentRuleTab != 1" [isReadonly]="_isReadonly"></mod-edit-account-transfer-rules>

                        <mod-edit-account-to-resource-rules [hidden]="_currentRuleTab != 2" [isReadonly]="_isReadonly"></mod-edit-account-to-resource-rules>

                        <mod-edit-resource-transfer-rules [hidden]="_currentRuleTab != 3" [isReadonly]="_isReadonly"></mod-edit-resource-transfer-rules>

                        <mod-edit-resource-to-task-rules [hidden]="_currentRuleTab != 4" [isReadonly]="_isReadonly"></mod-edit-resource-to-task-rules>

                        <mod-edit-resource-task-split-rules [hidden]="_currentRuleTab != 6" [isReadonly]="_isReadonly"></mod-edit-resource-task-split-rules>

                        <mod-edit-resource-task-transfer-rules [hidden]="_currentRuleTab != 5" [isReadonly]="_isReadonly"></mod-edit-resource-task-transfer-rules>

                        <mod-edit-resource-task-to-activity-rules [hidden]="_currentRuleTab != 7" [isReadonly]="_isReadonly"></mod-edit-resource-task-to-activity-rules></div></div><div class="form-group--buttons form-group"><div class="form-row"><lg-button textLc="FW.SAVE" (click)="_apply()" [isDisabled]="_isLoading()"></lg-button>
                    <lg-button textLc=".SaveAndClose" (click)="_save()" [isDisabled]="_isLoading()"></lg-button>
                    <lg-button textLc="FW.CLOSE" (click)="_close()"></lg-button></div></div>