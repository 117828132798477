import { NgModule } from "@angular/core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { UiCoreModule } from "@logex/framework/ui-core";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SharedModule } from "@shared/shared.module";
import { EditRulesDialogComponent } from "./edit-rules-dialog.component";
import { RulesDialogSwitcherService } from "./services/rules-dialog-switcher.service";
import { EditAccountSplitRulesComponent } from "./components/edit-split-rules/edit-account-split-rules.component";
import {
    EditAccountToResourceRulesComponent
} from "./components/edit-account-to-resource-rules/edit-account-to-resource-rules.component";
import {
    EditAccountTransferRulesComponent
} from "./components/edit-account-transfer-rules/edit-account-transfer-rules.component";
import {
    EditResourceTaskSplitRulesComponent
} from "./components/edit-resource-task-split-rules/edit-resource-task-split-rules.component";
import {
    EditResourceTaskToActivityRulesComponent
} from "./components/edit-resource-task-to-activity-rules/edit-resource-task-to-activity-rules.component";
import {
    EditResourceTaskTransferRulesComponent
} from "./components/edit-resource-task-transfer-rules/edit-resource-task-transfer-rules.component";
import {
    EditResourceToTaskRulesComponent
} from "./components/edit-resource-to-task-rules/edit-resource-to-task-rules.component";
import {
    EditResourceTransferRulesComponent
} from "./components/edit-resource-transfer-rules/edit-resource-transfer-rules.component";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UiCoreModule,
        UiToolboxModule,
        LgLayoutModule,
        LgLocalizationModule,
        LgPivotTableModule,
        DragDropModule,
        SharedModule,
    ],
    declarations: [
        EditRulesDialogComponent,
        EditAccountSplitRulesComponent,
        EditAccountToResourceRulesComponent,
        EditAccountTransferRulesComponent,
        EditResourceTaskSplitRulesComponent,
        EditResourceTaskToActivityRulesComponent,
        EditResourceTaskTransferRulesComponent,
        EditResourceToTaskRulesComponent,
        EditResourceTransferRulesComponent,
    ],
    providers: [
        EditRulesDialogComponent,
        RulesDialogSwitcherService,

        RuleFilterSelectorService,
    ],
})
export class EditRulesDialogModule {
}
