import * as _ from "lodash";
import { Component, inject, Input, ViewEncapsulation } from "@angular/core";
import { firstValueFrom } from "rxjs";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { DefinitionsCostDriver, IAppDefinitions } from "@shared/app-definitions.types";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { TransferRulesComponentBase } from "../bases/transfer-rules-base/transfer-rules-component-base";
import { SOURCE_RULE_FILTER_SELECTOR, TARGET_RULE_FILTER_SELECTOR, TransferRulesPivotLevel2 } from "../bases/transfer-rules-base/types";

import { EditResourceTaskSplitRulesGateway } from "./gateways/edit-resource-task-split-rules-gateway";
import { CostDriverInfo, RuleGroup } from "../bases/rules-base/gateways/rules-gateway.types";
import { TransferCostDriverInfo, TransferRule } from "../bases/transfer-rules-base/gateway/transfer-rules-gateway.type";
import { SubtaskManagementDialog } from "@shared/dialogs/subtask-management-dialog/subtask-management-dialog.component";


@Component({
    selector: "mod-edit-resource-task-split-rules",
    templateUrl: "./edit-resource-task-split-rules.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog.ResourceTaskSplitRules"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        { provide: TARGET_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
    ],
})
export class EditResourceTaskSplitRulesComponent extends TransferRulesComponentBase {

    private _subtaskManagementDialog = inject(SubtaskManagementDialog);
    _gateway = inject(EditResourceTaskSplitRulesGateway);

    // ----------------------------------------------------------------------------------
    // Fields

    @Input() isReadonly = false;

    _costDriverDropdown: IDropdownDefinition<number>;
    protected _defaultCostDriver: DefinitionsCostDriver;

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["subtask"];


    // ----------------------------------------------------------------------------------
    // Methods

    protected _processLoadedData(
        rules: TransferRule[],
        groups: RuleGroup[],
        costDrivers: CostDriverInfo[]
    ): void {
        super._processLoadedData(rules, groups, costDrivers);

        const orderedCostDrivers = _.sortBy(
            _.map(costDrivers, x => {
                const def = this._definitions.costDriver[x.costDriverId];
                if (def != null) return def;
                return {
                    id: x.costDriverId,
                    name: this._lgTranslate.translate("APP._.Unknown"),
                } as DefinitionsCostDriver;
            }),
            "orderBy"
        );
        this._costDriverDropdown = this._defineCostDriverDropdown(orderedCostDrivers);
        this._defaultCostDriver = _.first(orderedCostDrivers);
    }


    protected _processLoadedCostDriverInfo(costDrivers: TransferCostDriverInfo[]): void {
        this._costDriversInfoLookup = _.keyBy(costDrivers, "costDriverId");
    }


    protected _newRule(groupId: number, position: number) {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
            costDriverId: this._defaultCostDriver.id,
        } as TransferRulesPivotLevel2;
    }


    _openSubtaskManager(): void {
        this._subtaskManagementDialog
            .show({
                isReadonly: this.isReadonly,
            });
    }


    async save(): Promise<boolean> {
        await super.save();
        await firstValueFrom(this._definitions.reload("subtask"));
        return true;
    }
}
