export enum PageTabs {
    OrganisationCost,
    AllocateToResource,
    AllocateSupportCost,
    AllocateToProduction,
}


export enum Tabs {
    AccountSplitRules,
    AccountTransferRules,
    AccountToResourceRules,
    ResourceTransferRules,
    ResourceToTaskRules,
    ResourceTaskTransferRules,
    ResourceTaskSplitRules,
    ResourceTaskToActivityRules,
}
