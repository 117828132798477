import * as _ from "lodash";
import { Component, inject, Input, ViewEncapsulation } from "@angular/core";
import { of } from "rxjs";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { IAppDefinitions } from "@shared/app-definitions.types";
import { dropdownBoundToDefinition } from "@shared/helpers/dropdownBoundToDefinition";
import { SOURCE_RULE_FILTER_SELECTOR } from "../bases/transfer-rules-base/types";
import { RulesComponentBase } from "../bases/rules-base/rules-component-base";

import { EditAccountSplitRulesGateway } from "./gateways/edit-account-split-rules-gateway";
import { CostDriverInfoExt } from "./gateways/edit-account-split-rules-gateway.types";
import { RulesPivotLevel2Ext } from "./pivots/account-split-rules-pivot.types";
import { SubAccountManagementDialog } from "@shared/dialogs/sub-account-management-dialog/sub-account-management-dialog.component";


@Component({
    selector: "mod-edit-account-split-rules",
    templateUrl: "./edit-account-split-rules.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog.AccountSplitRules"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
    ],
})
export class EditAccountSplitRulesComponent extends RulesComponentBase<RulesPivotLevel2Ext, CostDriverInfoExt> {

    private _subAccountManagementDialog = inject(SubAccountManagementDialog);
    _gateway = inject(EditAccountSplitRulesGateway);

    // ----------------------------------------------------------------------------------
    // Fields

    @Input() isReadonly = false;

    _costDriversInfo: CostDriverInfoExt[];

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["subAccount"];
    _subAccountDropdown: IDropdownDefinition<any>;


    // ----------------------------------------------------------------------------------
    // Methods

    async activate(): Promise<void> {
        await super.activate();
        this._refillSubAccountDropdown();
    }


    protected _processLoadedCostDriverInfo(costDrivers: CostDriverInfoExt[]): void {
        this._costDriversInfo = costDrivers;
        this._costDriversInfoLookup = _.keyBy(costDrivers, x => x.costDriverId);
    }


    private _refillSubAccountDropdown() {
        this._subAccountDropdown = dropdownBoundToDefinition({
            definitions: this._definitions,
            section: "subAccount",
        });
    }

    protected _isValid(): boolean {
        return super._isValid() && _.every(this._rulesPivot.all, row1 => _.every(row1.children,
            row2 => this._getCostDriverInfo(row2)?.maxSubAccounts === 0 || row2.subAccounts?.length > 0
        ));
    }

    protected _newRule(groupId: number, position: number) {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
            subAccounts: [],
        } as RulesPivotLevel2Ext;
    }


    protected _updateRuleAfterChanges(row: RulesPivotLevel2Ext, changedFields: Readonly<Partial<RulesPivotLevel2Ext>>): void {
        const orderedCostDrivers = _.sortBy(
            _.map(this._costDriversInfo, x => this._definitions.costDriver[x.costDriverId]),
            "orderBy"
        );

        if (!_.some(this._costDriversInfo, x => x.costDriverId === row.costDriverId)) {
            row.costDriverId = _.first(orderedCostDrivers)?.id;
        }

        const costDriverInfo = this._getCostDriverInfo(row);
        row.costDriverDropdown = this._defineCostDriverDropdown(orderedCostDrivers);
        row.maxSubAccounts = costDriverInfo?.maxSubAccounts;

        row.allowedSources = costDriverInfo?.uids || [];
        for (const source of Object.keys(row.filters || {})) {
            if (!row.allowedSources.includes(source)) {
                delete row.filters[source];
            }
        }

        row.subAccounts = row.maxSubAccounts === 0
            ? null
            : (row.subAccounts || []).splice(0, row.maxSubAccounts);
    }


    _openSubAccountManager(): void {
        this._subAccountManagementDialog
            .show({
                isReadonly: this.isReadonly,
            })
            .then(() => {
                this._refillSubAccountDropdown();
            });
    }


    _isSourceAllowed(row: RulesPivotLevel2Ext, field: string): boolean {
        return row.allowedSources?.includes(field);
    }


    protected _areEqualRules(row: RulesPivotLevel2Ext, originalRow: RulesPivotLevel2Ext): boolean {
        return super._areEqualRules(row, originalRow)
            && this._areEqualFilters(this._sourceSelectorConfig, row.filters, originalRow.filters)
            && _.isEqual(row.subAccounts, originalRow.subAccounts);
    }

    _getSubAccountOptionsCallback() {
        return () => of(_.keys(this._definitions.subAccount));
    }

    _getDisplayNameCallback() {
        return id => this._definitions.getDisplayName("subAccount", id);
    }

    _getOrderByCallback() {
        return id => this._definitions.getOrderBy("subAccount", id);
    }
}
