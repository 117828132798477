import * as _ from "lodash";
import { Component, inject, Input, ViewEncapsulation } from "@angular/core";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";

import { DefinitionsCostDriver } from "@shared/app-definitions.types";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";

import { TransferRulesComponentBase } from "../bases/transfer-rules-base/transfer-rules-component-base";
import { RuleGroup } from "../bases/rules-base/gateways/rules-gateway.types";
import { SOURCE_RULE_FILTER_SELECTOR, TARGET_RULE_FILTER_SELECTOR, TransferRulesPivotLevel2 } from "../bases/transfer-rules-base/types";

import { EditAccountRulesGateway } from "./gateways/edit-account-transfer-rules-gateway";
import { TransferCostDriverInfo, TransferRule } from "../bases/transfer-rules-base/gateway/transfer-rules-gateway.type";


@Component({
    selector: "mod-edit-account-transfer-rules",
    templateUrl: "./edit-account-transfer-rules.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._EditRulesDialog.AccountTransferRules"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        { provide: TARGET_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
    ],
})
export class EditAccountTransferRulesComponent extends TransferRulesComponentBase {

    _gateway = inject(EditAccountRulesGateway);

    // ----------------------------------------------------------------------------------
    // Fields

    @Input() isReadonly = false;

    _costDriverDropdown: IDropdownDefinition<number>;
    protected _defaultCostDriver: DefinitionsCostDriver;


    // ----------------------------------------------------------------------------------
    // Methods

    protected _processLoadedData(
        rules: TransferRule[],
        groups: RuleGroup[],
        costDrivers: TransferCostDriverInfo[]
    ): void {
        super._processLoadedData(rules, groups, costDrivers);

        const orderedCostDrivers = _.sortBy(
            _.map(costDrivers, x => this._definitions.costDriver[x.costDriverId]),
            "orderBy"
        );
        this._costDriverDropdown = this._defineCostDriverDropdown(orderedCostDrivers);
        this._defaultCostDriver = _.first(orderedCostDrivers);
    }


    protected _processLoadedCostDriverInfo(costDrivers: TransferCostDriverInfo[]): void {
        this._costDriversInfoLookup = _.keyBy(costDrivers, "costDriverId");
    }


    protected _newRule(groupId: number, position: number) {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
            costDriverId: this._defaultCostDriver.id,
        } as TransferRulesPivotLevel2;
    }
}
